<template>
    <b-modal v-model="show"
             centered
             hide-footer
             hide-backdrop
             title="Registration"
             @hidden="closeModal">
        <template #modal-header-close>
            <img src="../../assets/images/icons/circle_close.svg" class="icon" alt="關閉"/>
        </template>
        <p class="title">
            <img src="@/assets/images/icons/registration.svg" class="icon" alt="報名方式"/> 報名方式
        </p>
        <div class="d-flex align-items-center justify-content-between info-card">
            <div class="content-wrapper">
                <p class="info-title">線上表單報名</p>
                <p class="info-description">
                    使用線上表單填寫報名課程，完成表單填寫送出後請撥打下方電話確認完成報名
                </p>
                <p class="form-link">
                    報名連結：<a href="https://docs.google.com/forms/d/e/1FAIpQLSf92IcKXwkjx2F5a8KJTq6bKXP4OaS1zPrVaGXKYjwVuCRYDA/viewform" class="link-text" target="_blank">點我</a>
                </p>
            </div>
            <img src="../../assets/images/registration_form_qrcode.png" class="qrcode-img" alt="線上表單報名 qrcode"/>
        </div>
        <div class="d-flex align-items-center justify-content-between info-card">
            <div class="content-wrapper">
                <p class="info-title">電話諮詢/報名</p>
                <p class="info-description">
                    耀迅 林先生 <span class="d-block font-roboto-500">Tel : 0958-175798 / Line ID : @unustech</span>
                </p>
            </div>
            <img src="../../assets/images/registration_line_qrcode.png" class="qrcode-img" alt="耀迅科技Line qrcode"/>
        </div>
        <p class="title">
            <img src="@/assets/images/icons/credit_card.svg" class="icon" alt="收款資訊"/> 收款資訊
        </p>
        <div class="d-flex align-items-center justify-content-between collection-wrapper">
            <p class="font-roboto-400">0080000 華南銀行 - 642100223189</p>
            <button type="button" class="btn copy-btn" @click.stop="copyHandler">
                <img src="@/assets/images/icons/copy.svg" alt="複製收款資訊"/>
            </button>
        </div>
    </b-modal>
</template>
<script>
export default {
    name: 'RegistrationModal',
    props: {
        value: Boolean
    },
    watch: {
        value(newVal) {
            this.show = newVal
        }
    },
    data() {
        return {
            show: this.value
        }
    },
    methods: {
        closeModal() {
            this.$emit('input', false);
        },
        copyHandler() {
            const container = document.querySelector('.modal-content');
            this.$copyText('0080000 華南銀行 642100223189', container);
        }
    }
}
</script>
<style lang="scss" scoped>
::v-deep .modal-dialog{
    max-width: 550px;
}
.title{
    display:       flex;
    align-items:   center;
    gap:           5px;
    margin-bottom: 10px;
    font-weight:   500;
    .icon{
        margin-top: 2px;
    }
}
.info-card{
    margin-bottom: 10px;
    border:        1px solid #E1E1E1;
    border-radius: 10px;
    padding:       15px 15px 15px 20px;
    gap:           20px;
    @media (max-width: 575px){
        gap: 10px;
    }
    .info-title{
        font-size:     18px;
        color:         var(--secondary-color);
        font-weight:   500;
        margin-bottom: 5px;
    }
    .info-description,
    .form-link{
        color: var(--secondary-dark-color);
    }
    .link-text{
        color:           var(--secondary-dark-color);
        font-weight:     bold;
        text-decoration: underline;
        &:hover{
            filter: brightness(1.5);
        }
    }
    .qrcode-img{
        width: 110px;
    }
}
.collection-wrapper{
    padding:          10px 20px;
    background-color: var(--secondary-color);
    color:            white;
    font-size:        20px;
    border-radius:    50px;
    gap:              10px;
    @media(max-width: 575px){
        font-size: 16px;
    }
    .copy-btn{
        display:         flex;
        align-items:     center;
        justify-content: center;
        padding:         0;
    }
}
</style>