<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="12.619" height="14.021" viewBox="0 0 12.619 14.021">
        <path d="M14.217,2.4h-.7V1h-1.4V2.4H6.505V1H5.1V2.4H4.4a1.4,1.4,0,0,0-1.4,1.4L3,13.619a1.4,1.4,0,0,0,1.4,1.4h9.815a1.406,1.406,0,0,0,1.4-1.4V3.8A1.406,1.406,0,0,0,14.217,2.4Zm0,11.217H4.4V5.907h9.815ZM5.8,7.31H9.31v3.505H5.8Z"
              transform="translate(-3 -1)" fill="currentColor"/>
    </svg>
</template>
<script>
export default {
    name: 'CalenderIcon'
}
</script>