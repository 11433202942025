<template>
    <nav aria-label="breadcrumb" class="breadcrumb-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <ol class="breadcrumb">
                        <li v-for="(item,index) in breadcrumbList" :key="`link_${index}`" class="breadcrumb-item">
                            <router-link v-if="item.url !== '/'" :to="item.url" class="link-item" exact>
                                {{ item.name }}
                            </router-link>
                            <button v-else type="button" class="border-0 p-0 link-item" @click="comingSoon">
                                {{ item.name }}
                            </button>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">{{ current }}</li>
                    </ol>
                </div>
            </div>
        </div>
    </nav>
</template>
<script>
export default {
    name: 'Breadcrumb',
    props: {
        current: {
            type: String,
            default: ''
        }
    },
    computed: {
        breadcrumbList() {
            return this.$route.meta.breadcrumb && this.$route.meta.breadcrumb.length !== 0 ? this.$route.meta.breadcrumb : []
        }
    }
}
</script>
<style lang="scss" scoped>
.breadcrumb-wrapper{
    background-color: var(--gray-color);
    .breadcrumb{
        background-color: transparent;
        padding:          24px 0;
        margin-bottom:    0;
        @media (max-width: 575px){
            padding: 12px 0;
        }
    }
    .breadcrumb-item{
        font-weight: 500;
        .link-item{
            color: black;
            &:hover{
                color:           var(--secondary-color);
                text-decoration: none;
            }
        }
        & + .breadcrumb-item::before{
            content: '>';
            color:   black;
        }
        &.active{
            color: var(--secondary-color);
        }
    }
}
</style>