<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="10.5" height="15" viewBox="0 0 10.5 15">
        <path d="M14.75,6.5a4.5,4.5,0,0,0-9,0c0,3.375,4.5,8.25,4.5,8.25S14.75,9.875,14.75,6.5Zm-6,0A1.5,1.5,0,1,1,10.25,8,1.5,1.5,0,0,1,8.75,6.5ZM5,15.5V17H15.5V15.5Z"
              transform="translate(-5 -2)" fill="currentColor"/>
    </svg>
</template>
<script>
export default {
    name: 'AddressIcon'
}
</script>