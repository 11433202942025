<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path d="M13.392,6.608A4.779,4.779,0,0,0,10,5.2V10L6.608,13.392a4.8,4.8,0,1,0,6.784-6.784ZM10,2a8,8,0,1,0,8,8A8,8,0,0,0,10,2Zm0,14.4A6.4,6.4,0,1,1,16.4,10,6.4,6.4,0,0,1,10,16.4Z"
              transform="translate(-2 -2)" fill="currentColor"/>
    </svg>
</template>
<script>
export default {
    name: 'DateIcon'
}
</script>