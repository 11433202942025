import { render, staticRenderFns } from "./VideoModal.vue?vue&type=template&id=6c0676e6&scoped=true"
import script from "./VideoModal.vue?vue&type=script&lang=js"
export * from "./VideoModal.vue?vue&type=script&lang=js"
import style0 from "./VideoModal.vue?vue&type=style&index=0&id=6c0676e6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c0676e6",
  null
  
)

export default component.exports