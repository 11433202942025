<template>
    <div class="wrapper">
        <button type="button" class="btn go-top-btn" @click="toTop">
            <img src="@/assets/images/icons/go_top.svg" alt="回到頂層"/>
        </button>
        <header-view/>
        <div class="wrapper-content">
            <h1 class="d-none">羽客多-科學羽球訓練整合課程</h1>
            <router-view/>
        </div>
        <footer-view/>
    </div>
</template>
<script>
import FooterView from '@/components/FooterView/FooterView.vue';
import HeaderView from '@/components/HeaderView/HeaderView.vue';

export default {
    name: 'DefaultLayout',
    components: {
        FooterView,
        HeaderView
    },
    methods: {
        toTop() {
            this.$scrollTo('body');
        }
    }
}
</script>
<style lang="scss" scoped>
.go-top-btn{
    position:         fixed;
    right:            2%;
    bottom:           3%;
    background-color: var(--secondary-color);
    padding:          25px 15px;
    border-radius:    50px;
    z-index:          1;
}
.wrapper-content{
    margin-bottom: 40px;
}
</style>