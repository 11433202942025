<template>
    <main></main>
</template>
<script>
export default {
    name: 'Certification',
    mounted() {
        this.fetchCertification();
    },
    methods: {
        async fetchCertification() {
            const token = this.$route.query.token;
            const systemId = this.$route.query.system_id;

            try {
                const res = await this.$http.get('/certification', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    params: {
                        system_id: systemId ? Number(systemId) : null
                    }
                });
                if(res.status === 200) {
                    if(confirm('驗證成功，請回到原系統登入')) {
                        window.close();
                    }
                }
            } catch(err) {
                alert('驗證失敗，請稍後再試');
                window.close();
            }
        }
    }
}
</script>