const courses = [
    {
        ladder: 1,
        name: '智慧羽球團體課程(第一梯次)',
        startDate: '2024-06-15',
        endDate: '2024-06-19'
    },
    {
        ladder: 2,
        name: '智慧羽球團體課程(第二梯次)',
        startDate: '2024-07-01',
        endDate: '2024-07-05'
    },
    {
        ladder: 3,
        name: '智慧羽球團體課程(第三梯次)',
        startDate: '2024-07-15',
        endDate: '2024-07-19'
    },
    {
        ladder: 4,
        name: '智慧羽球團體課程(第四梯次)',
        startDate: '2024-07-22',
        endDate: '2024-07-26'
    },
    {
        ladder: 5,
        name: '智慧羽球團體課程(第五梯次)',
        startDate: '2024-07-29',
        endDate: '2024-08-02'
    },
    {
        ladder: 6,
        name: '智慧羽球團體課程(第六梯次)',
        startDate: '2024-08-05',
        endDate: '2024-08-09'
    },
    {
        ladder: 7,
        name: '智慧羽球團體課程(第七梯次)',
        startDate: '2024-08-12',
        endDate: '2024-08-16'
    },
    {
        ladder: 8,
        name: '智慧羽球團體課程(第八梯次)',
        startDate: '2024-08-19',
        endDate: '2024-08-23'
    }
]
export default courses;