<template>
    <div id="app">
        <component :is="layout"/>
    </div>
</template>

<script>
import AuthLayout from '@/layouts/AuthLayout.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';

export default {
    name: 'App',
    components: {
        AuthLayout,
        DefaultLayout
    },
    watch: {
        $route: {
            handler: function(to) {
                if(to.meta.layout !== undefined) {
                    this.layout = to.meta.layout
                } else {
                    this.layout = 'DefaultLayout'
                }
            },
            immediate: true
        }
    },
    data() {
        return {
            layout: null
        }
    }
}
</script>
