<template>
    <b-modal v-model="show"
             centered
             hide-footer
             hide-backdrop
             size="xl"
             title="Video"
             @hidden="closeModal">
        <template #modal-header-close>
            <img src="../../assets/images/icons/circle_close.svg" class="icon" alt="關閉"/>
        </template>
        <!--        影片-->
        <video src="/" class="video"></video>
        <p class="video-title">智慧羽球團體課程形象宣傳影片</p>
        <div class="d-flex flex-wrap align-items-center justify-content-between info-wrapper">
            <ul class="d-flex flex-wrap info-list">
                <li v-for="(item,index) in infoList"
                    :key="`info_${index}`"
                    class="d-flex align-items-center info-item">
                    <component v-if="item.svg" :is="item.svg" class="icon"></component>
                    <img v-else :src="item.icon" class="icon" alt="分類"/>
                    <p class="text">{{ item.text }}</p>
                </li>
            </ul>
            <ul class="d-flex align-items-center ml-auto operate-list">
                <li>
                    <button type="button" class="btn operate-btn" @click="comingSoon">
                        <star-icon class="icon"/>
                        <span>收藏</span>
                    </button>
                </li>
                <li>
                    <button type="button" class="btn operate-btn" @click="comingSoon">
                        <share-icon class="icon"/>
                        <span>分享</span>
                    </button>
                </li>
            </ul>
        </div>
        <p class="text-justify video-description">
            本次團體活動羽潮羽球館舉行，特色在於結合科技智能設備，與提供即時回饋運動數據，並紀錄學員成長，以科學數據優化運動體驗。同時，影像串流系統也將提供給學員，豐富活動體驗樂趣。
        </p>
        <ul class="d-flex align-items-center video-tag-list">
            <li class="tag-item">TAG</li>
            <li v-for="(item,index) in tagList"
                :key="`tag_${index}`"
                class="tag-item">
                <p class="tag-text">{{ item }}</p>
            </li>
        </ul>
    </b-modal>
</template>
<script>
import CategoryIcon from '@/components/Svg/CategoryIcon.vue';
import DateIcon from '@/components/Svg/DateIcon.vue';
import StarIcon from '@/components/Svg/StarIcon.vue';
import ShareIcon from '@/components/Svg/ShareIcon.vue';

export default {
    name: 'VideoModal',
    props: {
        value: Boolean
    },
    components: {
        CategoryIcon,
        DateIcon,
        StarIcon,
        ShareIcon
    },
    watch: {
        value(newVal) {
            this.show = newVal
        }
    },
    data() {
        return {
            show: this.value,
            infoList: [
                {
                    text: '課程形象',
                    svg: CategoryIcon
                },
                {
                    text: '2024.04.18',
                    svg: DateIcon
                },
                {
                    text: '128',
                    icon: require('@/assets/images/icons/watch.svg')
                }
            ],
            tagList: ['課程形象', '羽球初學', '形象片', 'AI推廣']
        }
    },
    methods: {
        closeModal() {
            this.$emit('input', false);
        }
    }
}
</script>
<style lang="scss" scoped>
.video{
    width:            100%;
    height:           600px;
    border-radius:    10px;
    background-color: #A0A0A0;
    margin-bottom:    15px;
    @media(max-width: 991px){
        height: 300px;
    }
}
.video-title{
    font-size:     20px;
    font-weight:   500;
    color:         var(--secondary-dark-color);
    margin-bottom: 5px;
}
.info-wrapper{
    margin-bottom: 15px;
    column-gap:    15px;
    row-gap:       10px;
    .info-list{
        column-gap: 30px;
        row-gap:    15px;
        .info-item{
            gap: 5px;
            .icon{
                width: 20px;
                color: var(--secondary-color);
            }
            .text{
                color:       var(--secondary-color);
                font-weight: 500;
            }
        }
    }
    .operate-list{
        gap: 10px;
        .operate-btn{
            display:       flex;
            align-items:   center;
            gap:           5px;
            padding:       5px 15px;
            border-radius: 50px;
            border:        1px solid #D1D8E2;
            color:         #A0A0A0;
            line-height:   1;
            .icon{
                width: 20px;
                @media (max-width: 991px){
                    width: 15px;
                }
            }
        }
    }
}
.video-description{
    margin-bottom: 10px;
}
.video-tag-list{
    color: #A0A0A0;
    .tag-item{
        margin-right: 10px;
        &:first-child{
            margin-right: 5px;
        }
        &:last-child{
            margin-right: 0;
        }
    }
    .tag-text{
        position: relative;
        &:before{
            content: '#'
        }
    }
}
</style>