<template>
    <section class="table-wrapper">
        <p class="text-center table-title">智慧羽球的不一樣</p>
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th>優點</th>
                    <th>科學羽球</th>
                    <th>一般羽球</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td class="advantage">費用</td>
                    <td>
                        <ul>
                            <li>
                                <p>一般票 : 500元/小時</p>
                            </li>
                            <li>
                                <p>早鳥票 : <span class="primary-text">306</span>元/小時</p>
                            </li>
                        </ul>
                    </td>
                    <td class="normal">
                        <p>400元/小時<span class="d-block">(*臺南市某初階班)</span></p>
                    </td>
                </tr>
                <tr>
                    <td class="advantage">技術指導</td>
                    <td>
                        <p class="d-flex align-items-center list-title">
                            專業年輕化團隊
                        </p>
                        <ul class="td-list">
                            <li>
                                <p>專業師資結合科技設備及報告</p>
                            </li>
                            <li>
                                <p>具體分析學員各項動作表現</p>
                            </li>
                        </ul>
                    </td>
                    <td class="normal">無明確師資團隊名單</td>
                </tr>
                <tr>
                    <td class="advantage">專業設施</td>
                    <td>
                        <p class="d-flex align-items-center list-title">
                            科技設備輔助學習
                        </p>
                        <ul class="td-list">
                            <li>
                                <p>專業智聯羽球拍(價值<span class="primary-text">4,800</span>元/支)</p>
                            </li>
                            <li>
                                <p>
                                    自動發球機(價值<span class="primary-text">20萬</span>元)對打，減少等待時間，讓老師擁有<span class="primary-text">充足時間指導調整</span>
                                </p>
                            </li>
                        </ul>
                    </td>
                    <td class="normal">學員自備羽球拍</td>
                </tr>
                <tr>
                    <td class="advantage">社交機會</td>
                    <td>
                        <p class="d-flex align-items-center list-title">
                            促進團隊合作、溝通和運動精神
                        </p>
                        <ul class="td-list">
                            <li>
                                <p>團體小活動</p>
                            </li>
                            <li>
                                <p>小組競賽、對打比賽</p>
                            </li>
                            <li>
                                <p class="primary-text">驚喜 : 特別的生物啟發課程</p>
                            </li>
                        </ul>
                    </td>
                    <td class="normal">自由互動活動</td>
                </tr>
                <tr>
                    <td class="advantage">綜合訓練</td>
                    <td>
                        <p class="d-flex align-items-center list-title">
                            系統化創新課程
                        </p>
                        <ul class="td-list">
                            <li>
                                <p>培養小朋友敏捷性</p>
                            </li>
                            <li>
                                <p>協調性和快速反應能力</p>
                            </li>
                        </ul>
                    </td>
                    <td class="normal">依老師資歷指導</td>
                </tr>
                <tr>
                    <td class="advantage">
                        <p>擊球報告<span class="d-block">數據意義</span></p>
                    </td>
                    <td>
                        <p class="d-flex align-items-center list-title">
                            擊球報告<span class="d-block">技能數據化、提升學習成就感</span>
                        </p>
                        <ul class="td-list">
                            <li class="half-item">
                                <p>揮拍速度 : 技術與力量</p>
                            </li>
                            <li class="half-item">
                                <p>揮拍力度 : 準確度</p>
                            </li>
                            <li class="half-item">
                                <p>殺球速度 : 爆發力</p>
                            </li>
                            <li class="half-item">
                                <p>前中後場的揮拍狀況 : 應變能力</p>
                            </li>
                            <li class="half-item">
                                <p>平抽球速度 : 控球及穩定性</p>
                            </li>
                            <li class="half-item">
                                <p>最大揮拍力度 : 潛在力量及爆發力</p>
                            </li>
                        </ul>
                    </td>
                    <td class="normal">無</td>
                </tr>
                <tr>
                    <td class="advantage">
                        <p>家長與小朋友<span class="d-block">獲得反饋</span></p>
                    </td>
                    <td>
                        <p class="d-flex align-items-center list-title">
                            學習歷程
                        </p>
                        <ul class="td-list">
                            <li class="half-item">
                                <p>擊球報告 : 呈現學習表現</p>
                            </li>
                            <li class="half-item">
                                <p>活動精彩紀錄包</p>
                            </li>
                            <li class="half-item">
                                <p>參與證書</p>
                            </li>
                            <li class="half-item">
                                <p>運動紀念禮包(價值700元)</p>
                            </li>
                            <li class="half-item">
                                <p>競賽獎狀</p>
                            </li>
                            <li class="half-item">
                                <p class="primary-text">充分運動鍛鍊體能</p>
                            </li>
                        </ul>
                    </td>
                    <td class="normal">參與證書</td>
                </tr>
                </tbody>
            </table>
        </div>
    </section>
</template>
<script>
export default {
    name: 'DifferentTable'
}
</script>
<style lang="scss" scoped>
.table-title{
    font-size:     20px;
    font-weight:   500;
    margin-bottom: 10px;
}
.table{
    font-size:     18px;
    margin-bottom: 20px;
    tr{
        &:nth-child(odd){
            background-color: #EFF3F8;
        }
    }
    th, td{
        vertical-align: middle;
        padding:        10px 20px;
        white-space:    nowrap;
        &:not(:nth-child(2)){
            text-align: center;
        }
        &:nth-child(2){
            max-width: 600px;
            @media (max-width: 1600px){
                max-width: 100%;
            }
        }
    }
    th{
        background-color: #377FB8;
        color:            white;
        border:           0;
        font-size:        20px;
    }
    td{
        border-right: 4px solid white;
        border-top:   0;
        &:last-child{
            border-right: 0;
        }
    }
    .advantage{
        color:       #377FB8;
        font-weight: 500;
    }
    .list-title{
        font-size:     18px;
        font-weight:   500;
        margin-bottom: 5px;
        position:      relative;
        &:before{
            content:      url('@/assets/images/icons/radius_star.svg');
            line-height:  1;
            margin-right: 5px;
            margin-top:   2px;
        }
    }
    .td-list{
        display:      flex;
        flex-wrap:    wrap;
        list-style:   '•' inside;
        font-size:    16px;
        padding-left: 25px;
        li{
            width: 100%;
            &.half-item{
                width: 50%;
            }
        }
        p{
            display: inline-block;
        }
    }
    .normal{
        color:     #616161;
        font-size: 16px;
    }
}
</style>