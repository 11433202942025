import axios from "axios";

const domain = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_DEVELOP_API_URL : (process.env.NODE_ENV === 'test' ? process.env.VUE_APP_TEST_API_URL : process.env.VUE_APP_PRODUCTION_API_URL);

const http = axios.create({
    baseURL: `${domain + process.env.VUE_APP_API_NAMESPACE_PATH}`,
    timeout: 2 * 60 * 1000,
    headers: {
        common: {
            'X-Requested-With': 'XMLHttpRequest'
        }
    }
})

http.interceptors.request.use(config => {
    const token = localStorage.getItem('token')
    if(!!token) {
        config.headers.common.Authorization = `Bearer ${token.replace(/\"/g, '')}`
    }
    return config
}, error => {
    return Promise.reject(error)
})

http.interceptors.response.use(response => {
    return response
}, error => {
    return Promise.reject(error)
})

export default http