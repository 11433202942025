export default [
    {
        path: '/certification',
        component: require('@/views/Auth/Certification.vue').default,
        meta: {layout: 'AuthLayout'}
    },
    {
        path: '/forgot',
        component: require('@/views/Auth/Forgot.vue').default,
        meta: {layout: 'AuthLayout'}
    },
    {
        path: '/app-privacy',
        component: require('@/views/AppPrivacy.vue').default,
        meta: {layout: 'AuthLayout'}
    },
    {
        path: '/course-introduction/:courseId',
        component: require('@/views/CourseIntroduction.vue').default,
        meta: {
            breadcrumb: [
                {
                    name: '首頁',
                    url: '/'
                },
                {
                    name: '課程參與',
                    url: '/'
                }
            ]
        }
    },
    {
        path: '*',
        redirect: '/course-introduction/1'
    }
];