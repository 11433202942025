<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="10.747" height="20.654" viewBox="0 0 10.747 20.654">
        <path d="M7,20.654V11.272h3.046l.56-3.781H7V4.831s.07-1.4,1.3-1.4h2.45V0H7.421A4.711,4.711,0,0,0,4.376,1.225a4.578,4.578,0,0,0-1.19,3.221V7.491H0v3.676H3.186v9.452Z"
              fill="currentColor"/>
    </svg>

</template>
<script>
export default {
    name: 'FacebookIcon'
}
</script>