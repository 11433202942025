<template>
    <b-modal v-model="show"
             centered
             hide-footer
             hide-backdrop
             title="Poster"
             size="lg"
             @hidden="closeModal">
        <template #modal-header-close>
            <img src="../../assets/images/icons/circle_close.svg" class="icon" alt="關閉"/>
        </template>
        <div class="poster-wrapper">
            <img src="@/assets/images/poster_large.png" class="w-100 poster-img" alt="智慧羽球團體課程招生中"/>
        </div>
    </b-modal>
</template>
<script>

export default {
    name: 'PosterModal',
    props: {
        value: Boolean
    },
    watch: {
        value(newVal) {
            this.show = newVal
        }
    },
    data() {
        return {
            show: this.value
        }
    },
    methods: {
        closeModal() {
            this.currentLecturerIndex = 1;
            this.$emit('input', false);
        }
    }
}
</script>