<template>
    <footer>
        <div class="img-wrapper">
            <div class="d-flex flex-column align-items-center justify-content-between text-center img-content">
                <p class="title">
                    打造客製化的訓練專案<span class="d-none d-md-inline-block">，</span><span class="d-block d-md-inline-block">快速提升訓練成效</span>
                </p>
                <p class="font-roboto-900 en-title mb-0">HOW TO TRAIN SPECIFICALLY?</p>
                <!--                <button type="button" class="btn secondary-btn" @click="comingSoon">立即開始體驗</button>-->
            </div>
        </div>
        <section class="footer-wrapper">
            <div class="d-flex flex-wrap footer-content">
                <div class="d-flex flex-row flex-lg-column align-items-center justify-content-center justify-content-lg-start logo-wrapper">
                    <img src="@/assets/images/footer_logo.png" class="logo-img" alt="羽客多Logo"/>
                    <p class="footer-description">
                        羽客多致力於研發與打造運動科學系統<span class="d-block">用AI與科技打造一套獨特的訓練方案</span>
                    </p>
                </div>
                <div class="d-flex flex-wrap content-wrapper">
                    <ul class="d-flex flex-wrap flex-md-nowrap justify-content-between nav-wrapper">
                        <template v-for="(columnItem,columnIndex) in footerList">
                            <li v-if="columnIndex !== footerList.length - 1"
                                :key="`column_${columnIndex}`"
                                class="list-wrapper">
                                <ul class="nav-list">
                                    <li :key="`column_${columnIndex}_0`"
                                        class="nav-item header-item"
                                        :class="{'text-white':columnItem[0].inHeader,'list-collapse':columnItem[0].isCollapse}">
                                        <p>{{ columnItem[0].name }}</p>
                                        <button type="button" class="btn collapse-btn" @click="collapseHandler(columnIndex)">
                                            <img src="@/assets/images/icons/arrow_down.svg" alt="展開/收合"/>
                                        </button>
                                    </li>
                                    <ul class="collapse-list">
                                        <template v-for="(item,index) in columnItem">
                                            <li v-if="index !== 0"
                                                :key="`column_${columnIndex}_${index}`"
                                                class="nav-item"
                                                :class="{'text-white':item.inHeader}">
                                                {{ item.name }}
                                            </li>
                                        </template>
                                    </ul>
                                </ul>
                            </li>
                            <li v-else class="list-wrapper">
                                <ul class="nav-list">
                                    <li v-for="(item,index) in columnItem"
                                        :key="`column_${columnIndex}_${index}`"
                                        class="nav-item"
                                        :class="{'text-white':item.inHeader}">
                                        {{ item.name }}
                                    </li>
                                </ul>
                            </li>
                        </template>
                    </ul>
                    <div class="follow-wrapper">
                        <p class="follow-title">Follow US</p>
                        <ul class="d-flex align-items-center follow-list">
                            <li class="follow-item">
                                <button type="button" class="btn follow-btn">
                                    <facebook-icon/>
                                </button>
                            </li>
                            <li class="follow-item">
                                <button type="button" class="btn follow-btn">
                                    <img src="@/assets/images/icons/instagram.svg" alt="instagram"/>
                                </button>
                            </li>
                            <li class="follow-item">
                                <button type="button" class="btn follow-btn">
                                    <img src="@/assets/images/icons/youtube.svg" alt="youtube"/>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <div class="d-flex flex-wrap justify-content-center copy-right-wrapper">
            <p>羽客多 科學訓練方案</p>
            <p>2024© Copyright All Rights Reserved.</p>
            <p>Design by NCKU Ai4dt<span>免責聲明‧隱私權政策</span></p>
        </div>
    </footer>
</template>
<script>
import FacebookIcon from '@/components/Svg/FacebookIcon.vue';

export default {
    name: 'FooterView',
    components: {
        FacebookIcon
    },
    data() {
        return {
            footerList: [
                [
                    {
                        name: '關於我們',
                        isCollapse: false,
                        inHeader: true,
                        url: '/'
                    },
                    {
                        name: '公司簡介',
                        inHeader: false,
                        url: '/'
                    },
                    {
                        name: '專業教練',
                        inHeader: false,
                        url: '/'
                    },
                    {
                        name: '團隊夥伴',
                        inHeader: false,
                        url: '/'
                    },
                    {
                        name: '合作場館',
                        inHeader: false,
                        url: '/'
                    },
                    {
                        name: '研發歷程',
                        inHeader: false,
                        url: '/'
                    }
                ],
                [
                    {
                        name: '最新消息',
                        inHeader: true,
                        isCollapse: false,
                        url: '/'
                    },
                    {
                        name: '活動課程',
                        inHeader: false,
                        url: '/'
                    },
                    {
                        name: '獲獎分享',
                        inHeader: false,
                        url: '/'
                    },
                    {
                        name: '學生成果',
                        inHeader: false,
                        url: '/'
                    }
                ],
                [
                    {
                        name: '課程介紹',
                        isCollapse: false,
                        inHeader: true,
                        url: '/'
                    },
                    {
                        name: '課程特色',
                        inHeader: false,
                        url: '/'
                    },
                    {
                        name: '近期開課',
                        inHeader: false,
                        url: '/'
                    },
                    {
                        name: '智慧羽球',
                        inHeader: false,
                        url: '/'
                    },
                    {
                        name: '學生教育',
                        inHeader: false,
                        url: '/'
                    }
                ],
                [
                    {
                        name: '產品開發',
                        isCollapse: false,
                        inHeader: true,
                        url: '/'
                    },
                    {
                        name: '裝置感測器',
                        inHeader: false,
                        url: '/'
                    },
                    {
                        name: '訓練發球機',
                        inHeader: false,
                        url: '/'
                    },
                    {
                        name: '訓練APP',
                        inHeader: false,
                        url: '/'
                    }
                ],
                [
                    {
                        name: '會員專區',
                        isCollapse: false,
                        inHeader: true,
                        url: '/'
                    },
                    {
                        name: '登入會員中心',
                        inHeader: false,
                        url: '/'
                    },
                    {
                        name: '我的課程',
                        inHeader: false,
                        url: '/'
                    },
                    {
                        name: '我的裝置',
                        inHeader: false,
                        url: '/'
                    }
                ],
                [
                    {
                        name: '購物車',
                        inHeader: true,
                        url: '/'
                    },
                    {
                        name: '聯絡我們',
                        inHeader: true,
                        url: '/'
                    },
                    {
                        name: '網站地圖',
                        inHeader: true,
                        url: '/'
                    }
                ]
            ]
        }
    },
    methods: {
        collapseHandler(index) {
            this.footerList[index][0].isCollapse = !this.footerList[index][0].isCollapse
        }
    }
}
</script>
<style lang="scss" scoped>
$footer-bg-color:     #222222;
$footer-border-color: #444444;
.img-wrapper{
    padding:  0 5%;
    position: relative;
    @media(max-width: 1499px){
        padding: 0;
    }
    &:before{
        content:          '';
        position:         absolute;
        left:             0;
        bottom:           0;
        height:           45%;
        width:            100%;
        background-color: $footer-bg-color;
        z-index:          -1;
    }
    .img-content{
        padding:             5% 15px 6%;
        background-image:    url('@/assets/images/footer.png');
        background-position: center;
        background-size:     cover;
        background-repeat:   no-repeat;
        color:               white;
        @media(max-width: 767px){
            padding: 10% 15px;
        }
    }
    .title{
        font-size:     20px;
        margin-bottom: 30px;
        @media(max-width: 575px){
            margin-bottom: 15px;
            font-size:     18px;
        }
    }
    .en-title{
        margin-bottom: 55px;
        font-size:     45px;
        @media(max-width: 575px){
            font-size:     40px;
            margin-bottom: 40px;
        }
    }
    .secondary-btn{
        border-radius: 50px;
        padding:       10px 40px;
        font-weight:   500;
    }
}
.footer-wrapper{
    background-color: $footer-bg-color;
    color:            white;
    padding:          3% 0 1%;
    .footer-content{
        padding-bottom: 2.5%;
        width:          100%;
        max-width:      1440px;
        margin:         0 auto;
        .logo-wrapper{
            padding:   0 40px;
            width:     100%;
            max-width: 320px;
            @media(max-width: 991px){
                max-width:     100%;
                padding:       0 20px 20px;
                border-bottom: 1px solid $footer-border-color;
            }
            @media(max-width: 575px){
                border-bottom: 0;
                padding:       0 15px;
            }
        }
        .logo-img{
            width:     100%;
            max-width: 240px;
            @media(max-width: 575px){
                max-width: 160px;
            }
        }
        .content-wrapper{
            flex: 1;
        }
        .nav-wrapper{
            flex:        1;
            padding:     0 40px 20px;
            border-left: 1px solid $footer-border-color;
            column-gap:  15px;
            @media(max-width: 991px){
                border-left: 0;
                padding-top: 20px;
            }
            @media(max-width: 767px){
                width: 100%;
                flex:  auto;
            }
            @media(max-width: 575px){
                padding: 20px 15px;
            }
            .list-wrapper{
                @media (max-width: 575px){
                    width:          100%;
                    margin-bottom:  15px;
                    padding-bottom: 15px;
                    border-bottom:  1px solid $footer-border-color;
                }
                &:last-child{
                    @media (max-width: 575px){
                        margin-bottom:  0;
                        padding-bottom: 0;
                        border-bottom:  0;
                    }
                }
            }
            .nav-item{
                margin-bottom: 8px;
                color:         #A0A0A0;
                white-space:   nowrap;
                &:last-child{
                    margin-bottom: 0;
                }
            }
            .header-item{
                display:         flex;
                align-items:     center;
                justify-content: space-between;
                gap:             10px;
                margin-bottom:   8px;
                @media (max-width: 575px){
                    margin-bottom: 0;
                }
                .collapse-btn{
                    padding:    0;
                    display:    none;
                    transition: 0.3s;
                    @media(max-width: 575px){
                        display: block;
                    }
                }
            }
            //購物車、聯絡我們、網站地圖
            > li:last-child{
                .nav-list{
                    display:        flex;
                    flex-direction: column;
                    gap:            8px;
                    @media (max-width: 575px){
                        flex-direction:  row;
                        justify-content: space-between;
                    }
                }
                .nav-item{
                    margin-bottom: 0;
                }
            }
            .collapse-list{
                @media(max-width: 575px){
                    display: none;
                }
            }
            .list-collapse{
                &.header-item{
                    @media(max-width: 575px){
                        margin-bottom: 8px;
                    }
                }
                .collapse-btn{
                    transform: rotate(-180deg);
                }
                & + .collapse-list{
                    display: block;
                }
            }
        }
        .follow-wrapper{
            padding:     0 40px;
            border-left: 1px solid $footer-border-color;
            column-gap:  5px;
            @media(max-width: 991px){
                border-left: 0;
                padding-top: 20px;
            }
            @media(max-width: 575px){
                padding: 20px 15px;
            }
            .follow-title{
                margin-bottom: 8px;
            }
            .follow-list{
                column-gap: 15px;
                row-gap:    10px;
            }
        }
        .footer-description{
            font-size: 14px;
            color:     #646464;
        }
    }
    .follow-btn{
        width:            45px;
        height:           45px;
        background-color: #3C3C3C;
        color:            white;
        display:          flex;
        align-items:      center;
        justify-content:  center;
        border-radius:    50%;
    }
}
.copy-right-wrapper{
    background: linear-gradient(90deg, #10ADD4 0%, #07586D 100%) no-repeat padding-box;
    padding:    15px;
    text-align: center;
    color:      white;
    opacity:    0.9;
    font-size:  14px;
    row-gap:    5px;
    p{
        &:first-child{
            padding-right: 20px;
            @media(max-width: 767px){
                padding-right: 10px;
            }
        }
        &:nth-child(2){
            padding-right: 4px;
        }
        span{
            opacity:      0.4;
            padding-left: 10px;
            margin-left:  10px;
            border-left:  1px solid white;
        }
    }
    .other{
        opacity: 0.4;
    }
}
</style>