<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path d="M89-865h2v-6H89Zm1-8a.968.968,0,0,0,.713-.287A.968.968,0,0,0,91-874a.967.967,0,0,0-.287-.713A.967.967,0,0,0,90-875a.967.967,0,0,0-.712.288A.967.967,0,0,0,89-874a.968.968,0,0,0,.287.712A.968.968,0,0,0,90-873Zm0,13a9.737,9.737,0,0,1-3.9-.787,10.1,10.1,0,0,1-3.175-2.137,10.1,10.1,0,0,1-2.138-3.175A9.737,9.737,0,0,1,80-870a9.738,9.738,0,0,1,.787-3.9,10.1,10.1,0,0,1,2.138-3.175,10.1,10.1,0,0,1,3.175-2.138A9.739,9.739,0,0,1,90-880a9.739,9.739,0,0,1,3.9.787,10.1,10.1,0,0,1,3.175,2.138,10.1,10.1,0,0,1,2.137,3.175A9.738,9.738,0,0,1,100-870a9.737,9.737,0,0,1-.787,3.9,10.1,10.1,0,0,1-2.137,3.175,10.1,10.1,0,0,1-3.175,2.137A9.737,9.737,0,0,1,90-860Z"
              transform="translate(-80 880)" fill="currentColor"/>
    </svg>
</template>
<script>
export default {
    name: 'InfoIcon'
}
</script>