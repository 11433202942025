import Vue from 'vue';
import courses from '@/components/CourseIntroduction/courses';

// 今天日期是否在開課日期前
function isBeforeToday(date) {
    const today = Vue.prototype.$moment();
    return today.isBefore(Vue.prototype.$moment(date));
}

function isAfterToday(date) {
    const today = Vue.prototype.$moment();
    return today.isBefore(Vue.prototype.$moment(date));
}

export default async(to, from, next) => {
    if(to.path.includes('/course-introduction')) {
        const courseId = Number(to.params.courseId);
        const findCourse = _.find(courses, item => item.ladder === courseId);
        const findLastCourseLadder = courses[courses.length - 1].ladder; //最後一筆的ID

        if(findCourse) {
            const isExpired = isBeforeToday(findCourse.startDate);
            if(isExpired || courseId === findLastCourseLadder) { //如果已經是最後一筆就顯示該頁面
                next();
            } else {
                const findCurrentCourse = _.find(courses, item => isAfterToday((item.startDate)));
                if(findCurrentCourse) {
                    next(`/course-introduction/${findCurrentCourse.ladder}`);
                } else {
                    next(`/course-introduction/${findLastCourseLadder}`);
                }
            }
        } else {
            next(`/course-introduction/${findLastCourseLadder}`);
        }
    } else {
        next();
    }
};