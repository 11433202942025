<template>
    <h3 class="d-flex align-items-end title">
        <span class="font-roboto-900 en-title">COURSE</span>課程參與
    </h3>
</template>
<script>
export default {
    name: 'TitleTag'
}
</script>
<style lang="scss" scoped>
.title{
    position:       relative;
    font-size:      28px;
    font-weight:    bold;
    height:         100px;
    padding-bottom: 20px;
    margin-right:   15px;
    @media(max-width: 575px){
        font-size: 24px;
        height:    75px;
    }
    &:before{
        content:          '';
        position:         absolute;
        left:             -10px;
        bottom:           35px;
        width:            30px;
        height:           30px;
        background-color: var(--primary-color);
        border-radius:    50%;
        z-index:          -1;
        @media(max-width: 575px){
            width:  20px;
            height: 20px;
        }
    }
    .en-title{
        position:  absolute;
        left:      0;
        bottom:    2px;
        color:     #EFF3F8;
        font-size: 80px;
        opacity:   0.8;
        z-index:   -2;
        @media(max-width: 575px){
            font-size: 60px;
            bottom:    10px;
        }
    }
}
</style>