<template>
    <main>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="privacy-wrapper">
                        <h2 class="title">AI球拍與勝利APP隱私權政策</h2>
                        <div class="paragraph-spacing description">
                            <p>本隱私權政策(以下簡稱本政策)適用於勝利體育事業股份有限公司以及關係企業、子公司與分支機構（下稱「本公司」、「我」、「我們」）透過AI球拍（下稱「本設備」）提供之服務。本設備係以Wi-Fi模組與本設備連結之移動裝置（例如智慧型手機或平板設備）進行訊號連結與傳輸，並與本公司委託之第三方協力服務商 (耀迅科技股份有限公司，以下簡稱協力服務商)的雲端系統進行連結。在與本設備連接前，您使用的移動裝置必須先下載「勝利APP」(下稱本APP)。本APP會由協力服務商開發。您可依據您的移動裝置所使用之作業系統於Apple Store或 Google Play上下載。透過本設備、移動裝置以及本APP，您能夠享受本公司所提供之線上運動數據分析及相關延伸服務（下稱「本服務」）。</p>
                            <p>本政策提供您知悉，我們從您或您可能與本設備相連結之裝置所蒐集之個人資料種類，以及我們會如何去使用這些資料。若您有任何疑問，請透過下方「聯絡我們」與我們聯繫。</p>
                        </div>
                        <div class="paragraph-spacing">
                            <h3 class="sub-title">我們蒐集的資料</h3>
                            <ul class="list">
                                <li>
                                    <p class="item-title">當您創建本服務的帳號時</p>
                                    <p>在您使用本服務以前，您會被要求透過本APP創建新帳戶並登入。當您註冊帳戶時，我們會要求您依照登入的選擇方式提供下列資訊：</p>
                                    <ul class="info-list">
                                        <li class="info-item">姓名</li>
                                        <li class="info-item">聯絡電話</li>
                                        <li class="info-item">電子郵件</li>
                                    </ul>
                                    <p>您在創建帳戶時會被要求提供您的電子郵件及手機號碼，以用來（1）寄送帳戶註冊之驗證信函、（2）成功創建帳戶後，讓您可以登入您的帳戶以享受本設備以及本服務，以及（3）讓我們於必要時能夠通知到您，包括但不限於關於使用者條款或本隱私權條款之更新。</p>
                                </li>
                                <li>
                                    <p class="item-title">額外資訊</p>
                                    <p>另外我們還會依據提供本服務所需向您蒐集以下額外資訊：</p>
                                    <p>您的身高、體重、性別、年齡、持拍手、慣用腳、居住區域及出生日期等額外資訊我們也有可能蒐集到。您可以選擇不要輸入這些資訊，但請注意若不提供，因為缺少這些背景資訊我們將無法計算運動成果，所以您將無法藉由本服務得到您的運動成果。</p>
                                </li>
                                <li>
                                    <p class="item-title">當您使用本服務時蒐集的資料</p>
                                    <p>當您使用本服務，我們會透過本設備以及可能與本設備或本App連結之其他運動監測裝置蒐集關於您運動活動的資料（下稱「運動數據」）：</p>
                                    <ul class="pl-3">
                                        <li>運動時間與長度</li>
                                        <li>運動強度</li>
                                        <li>運動速度</li>
                                        <li>運動頻率</li>
                                        <li>本設備產出之運動數據（僅於本設備裝置開啟之情況下）</li>
                                    </ul>
                                </li>
                                <li>
                                    <p class="item-title">當您與我們聯繫時</p>
                                    <p>當您與我們聯繫時，我們可能無法避免請您提供額外的個人資料。然而，我們不會要求您提供超過為了提供給您客戶服務或是回覆您行使個資當事人權利必要範圍外的資料。</p>
                                </li>
                                <li>
                                    <p class="item-title">當您使用我們的服務時</p>
                                    <p>從您登入帳戶並使用本服務從事運動時，我們會自動蒐集本設備、本App以及您與本服務互動的資訊。包括本服務的使用資料（例如：您使用本服務的特徵、您瀏覽的頁面、從事的運動時長、和您的會員檔案的連結等等），裝置連結以及設置數據（例如您的ISP業者、您的裝置的地區以及語言設定、您的裝置的識別碼，例如IP位置以及device ID），以及地區資料（例如從該IP位置得出之地區，或能指出城市層級的資料）。</p>
                                    <p>上開我們在您使用本服務所自動蒐集的資料，讓我們可以排除您在使用本服務或本App中可能遇到的問題。</p>
                                </li>
                            </ul>
                        </div>
                        <div class="paragraph-spacing">
                            <h3 class="sub-title">我們如何留存您的資訊</h3>
                            <p>經我們蒐集您的資訊後，我們會存放於協力服務商的雲端伺服器內。我們會採取合理並合適的安全措施來保護這些從您蒐集來或與您相關的資訊，並保護不受到未經授權的存取、使用、更改、揭露或銷毀。資料將持續儲存，除非接到用戶明示要求刪除他存放於雲端伺服器內的帳戶資料，或處理此些資料的法定事由不再有效。請注意，並無百分之百安全的網際網路資料傳輸或儲存方式存在。因此，我們無法擔保資料百分之百安全。</p>
                            <p>協力服務商作為資料控制方，將以本政策規範資料蒐集、利用的目的和方式；（如有適用）您將自協力服務商收到關於您的資料之蒐集、利用及/或同意的相關資訊。</p>
                        </div>
                        <div class="paragraph-spacing">
                            <h3 class="sub-title">我們如何分享您的資訊</h3>
                            <p>我們分享您的資訊給下列對象：</p>
                            <ul class="list">
                                <li>其他本服務用戶。您在個人檔案中選擇的「名字」（下稱「別名」）以及您選擇上傳的圖片（下稱「頭像」），將提供您運動的資訊以及其他延伸資訊分享給其他使用者，如排行榜中，但並不會分享您的個人資料。</li>
                                <li>關係企業。我們會分享您的資訊給我們的關係企業，包括母公司或兄弟公司。例如我們可能會分享您的資訊給我們的關係企業作為客戶服務、行銷或技術操作之用。</li>
                                <li>商業合作夥伴及服務協力廠商。我們和服務協力廠商合作以存放資料、分析運動表現、影音串流與處理付款，並與商業合作夥伴合作以提供您更多更好的延伸服務。為了上述目的，我們所蒐集的資料可能會分享給這些廠商，並委由該廠商處理並使用您的資料，（如有適用）您將自我們或其商業合作夥伴及協力服務商收到關於您的資料之蒐集、利用及/或同意的相關資訊。</li>
                                <li>
                                    安全性或強迫揭露。<span class="font-weight-bold">當我們善意認為存取、使用、保存或揭露這些資訊是為了下述理由之合理必要的情況，我們會分享您的資訊：(a)遵守適用法令、法定程序或執行政府機關要求；(b)執行使用者條款，包括調查可能的違反行為；</span>(c)偵測、避免或以其他方式處理詐欺、安全性或技術性問題，或(d)依據法律要求或於法有據以保護本公司、本服務用戶或公眾的權利、財產或安全。我們也會分享您的資訊以回應行政機關的要求，包括為了符合國家安全或法律執行的要求。
                                </li>
                            </ul>
                        </div>
                        <div class="paragraph-spacing">
                            <h3 class="sub-title">第三方服務</h3>
                            <p>本服務可能會包括第三方服務的連結。所有您提供給該第三方網站或服務的資料都是直接提供給該網站或服務的所有人，而應適用於該方之隱私權條款。本隱私權條款並不適用於該些網站或服務，且我們並不負責其內容、隱私或安全性措施以及政策。為了保護您的資料，我們建議您仔細審閱您所存取之其他網站或服務的隱私權條款。</p>
                        </div>
                        <div class="paragraph-spacing">
                            <h3 class="sub-title">您的資料當事人權利</h3>
                            <p>經您的要求，我們將會以誠信努力來：</p>
                            <ul class="list">
                                <li>提供您存取以及複製您曾經透過您的帳戶提供給我們的資料</li>
                                <li>若有不準確情況，您得更正或補充此些資料</li>
                                <li>停止蒐集或繼續處理您的資料</li>
                                <li>刪除處理您的資料</li>
                                <li>若您想要更正或刪除您的資料，請透過「聯絡我們」的聯繫資訊寄送EMAIL給我們。</li>
                            </ul>
                            <p class="mb-5">您可透過寄送「聯絡我們」所載之EMAIL通知我們永久刪除您的帳戶。我們將以實際上可行之方式盡快刪除您的資訊，但有些資訊可能為了備查或法律要求而會以封存/備份方式留存。</p>
                            <p>請留意我們只能夠刪除你留存於本服務範圍內的資訊。我們無法刪除您分享給第三方的資訊。因此，即便你取消使用本服務，這些資料仍會留存於第三方網站與服務內。然而，當收到您的刪除要求，我們會寄送刪除通知給我們曾分享並有處理您的資訊的第三方協力廠商，及商業合作夥伴。</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
export default {
    name: 'AppPrivacy'
};
</script>
<style lang="scss" scoped>
.privacy-wrapper{
    padding:    5% 0;
    text-align: justify;
    p{
        margin-bottom: 10px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    ul{
        margin-bottom: 10px;
    }
    .title{
        font-size:     24px;
        text-align:    center;
        margin-bottom: 20px;
    }
    .sub-title{
        font-size:     20px;
        font-weight:   bold;
        margin-bottom: 10px;
    }
    .paragraph-spacing{
        margin-bottom: 40px;
    }
    .list{
        padding-left: 20px;
        list-style:   disc;
        > li{
            margin-bottom: 10px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .info-list{
        list-style: inside;
        li{
            &::marker{
                content: '➤ '
            }
        }
    }
}
</style>