<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="9.414" height="9.414" viewBox="0 0 9.414 9.414">
        <g transform="translate(-24.293 -366.793)">
            <g transform="translate(-0.5 -116)">
                <line x2="8" y2="8" transform="translate(25.5 483.5)" fill="none" stroke="currentColor" stroke-linecap="round"
                      stroke-width="1"/>
                <line x1="8" y2="8" transform="translate(25.5 483.5)" fill="none" stroke="currentColor" stroke-linecap="round"
                      stroke-width="1"/>
            </g>
        </g>
    </svg>
</template>
<script>
export default {
    name: 'CloseIcon'
}
</script>