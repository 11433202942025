<template>
    <b-modal v-model="show"
             centered
             hide-footer
             hide-backdrop
             title="Lecturer"
             @hidden="closeModal">
        <template #modal-header-close>
            <img src="../../assets/images/icons/circle_close.svg" class="icon" alt="關閉"/>
        </template>
        <transition name="fade" mode="out-in">
            <template v-for="(item,index) in lecturerList">
                <div v-if="currentLecturerIndex === index + 1" :key="`lecturer_${index}`">
                    <p class="title">{{ item.name }} 教練</p>
                    <div class="splide-wrapper">
                        <splide :options="swiperOption" :key="`swiper_${index}`">
                            <splide-slide v-for="(imgItem,imgIndex) in item.images" :key="`slide_${imgIndex}`">
                                <img :src="imgItem" class="w-auto h-100" alt=""/>
                            </splide-slide>
                        </splide>
                    </div>
                    <div class="content-wrapper">
                        <p class="content-title">師資訓練</p>
                        <p class="content-description">
                            {{ item.description }}
                        </p>
                    </div>
                    <div class="content-wrapper">
                        <p class="content-title">比賽經歷</p>
                        <ul class="content-description competition-experience-list">
                            <li v-for="(experienceItem,experienceIndex) in item.competitionExperience"
                                :key="`lecturer_${index}_experience_${experienceIndex}`"
                                class="competition-experience-item">
                                {{ experienceItem }}
                            </li>
                        </ul>
                    </div>
                </div>
            </template>
        </transition>
        <div class="d-flex align-items-center justify-content-between operate">
            <button type="button" class="btn secondary-btn" @click="comingSoon">查看本教練課程</button>
            <div class="d-flex align-items-center lecturer-change-wrapper">
                <button type="button" class="btn change-btn" @click="changeLecturerHandler(0)">
                    <img src="@/assets/images/icons/left_arrow.svg" alt="上一位"/>
                </button>
                <p class="font-roboto-700 current">
                    <span class="primary-text">{{ currentLecturerIndex }}</span> / {{ lecturerList.length }}
                </p>
                <button type="button" class="btn change-btn" @click="changeLecturerHandler(1)">
                    <img src="@/assets/images/icons/right_arrow.svg" alt="下一位"/>
                </button>
            </div>
        </div>
    </b-modal>
</template>
<script>
import {Splide, SplideSlide} from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/splide.min.css';

export default {
    name: 'LecturerModal',
    props: {
        value: Boolean,
        lecturerList: Array
    },
    components: {
        Splide,
        SplideSlide
    },
    watch: {
        value(newVal) {
            this.show = newVal;
        }
    },
    data() {
        return {
            swiperOption: {
                perPage: 1,
                autoWidth: true,
                arrows: false,
                pagination: false,
                gap: 15,
                breakpoints: {
                    575: {
                        autoWidth: false,
                        perPage: 1
                    }
                }
            },
            currentLecturerIndex: 1,
            show: this.value
        }
    },
    methods: {
        closeModal() {
            this.currentLecturerIndex = 1;
            this.$emit('input', false);
        },
        changeLecturerHandler(type) { // 0:prev; 1:next
            if(type === 0) {
                if(this.currentLecturerIndex === 1) return;
                this.currentLecturerIndex -= 1;
            } else {
                if(this.currentLecturerIndex === this.lecturerList.length) return;
                this.currentLecturerIndex += 1;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
::v-deep .modal-dialog{
    max-width: 550px;
}
.title{
    display:       flex;
    gap:           10px;
    margin-bottom: 15px;
    font-size:     18px;
    font-weight:   500;
    color:         var(--secondary-color);
}
.splide-wrapper{
    min-height: 230px;
}
::v-deep .splide{
    margin-bottom: 20px;
    img{
        border-radius: 10px;
        max-height:    230px;
        object-fit:    cover;
        @media(max-width: 575px){
            max-width: 345px;
        }
    }
}
.fade-enter-active,
.fade-leave-active{
    transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to{
    opacity: 0;
}
.content-wrapper{
    margin-bottom: 40px;
    @media(max-width: 575px){
        margin-bottom: 20px;
    }
    .content-title{
        font-weight:   500;
        padding-left:  8px;
        border-left:   5px solid var(--secondary-color);
        margin-bottom: 8px;
    }
    .content-description{
        color: #8A8A8A;
    }
    .competition-experience-list{
        max-height: 200px;
        overflow-y: auto;
        @media(max-width: 575px){
            max-height: 150px;
        }
        .competition-experience-item{
            margin-bottom: 10px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}
.operate{
    .lecturer-change-wrapper{
        column-gap: 20px;
    }
    .secondary-btn{
        padding:       10px 25px;
        border-radius: 50px;
    }
    .change-btn{
        background-color: transparent;
        border:           0;
        padding:          0;
    }
}

</style>