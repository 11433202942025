<template>
    <main>
        <div class="banner-wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h3 class="en-title">Scientific Badminton</h3>
                        <h2 class="title">科學羽球訓練整合方案</h2>
                        <p class="d-flex align-items-center sub-title">
                            <img src="@/assets/images/icons/badminton.png" class="icon" alt="科學羽球訓練整合方案"/>
                            應用科學力量打造未來之星
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <breadcrumb current="智慧羽球團體課程"/>
        <div class="content-wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex flex-wrap align-items-end justify-content-between header-wrapper">
                            <title-tag/>
                            <share class="share" :share-title="course.name"/>
                        </div>
                        <div class="flex-wrapper">
                            <article class="course-wrapper">
                                <!--                                影片-->
                                <section class="d-flex flex-column align-items-center justify-content-center video-section">
                                    <img src="@/assets/images/course_img.jpg" class="w-100 h-100" :alt="course.name"/>
                                    <!--                                    <button type="button" class="btn video-btn" @click="videoModal.show = true">-->
                                    <!--                                        <img src="@/assets/images/icons/video_play.svg" class="icon" alt="影片"/>-->
                                    <!--                                    </button>-->
                                </section>
                                <!--                                課程dateline-->
                                <section class="info-section">
                                    <h4 class="section-title">{{ course.name }}</h4>
                                    <p class="text-justify info-description">
                                        本次團體活動羽潮羽球館舉行，特色在於結合科技智能設備，與提供即時回饋運動數據，並紀錄學員成長，以科學數據優化運動體驗，豐富活動體驗樂趣。
                                    </p>
                                    <ol class="info-list">
                                        <li class="info-item">
                                            <h5 class="item-title">
                                                <img src="../assets/images/icons/circle_calender.svg" class="icon" alt="課程活動日期"/>
                                                課程活動日期
                                            </h5>
                                            <div class="item-content">
                                                <ul class="dateline-list">
                                                    <li class="dateline-item" :class="{'active':datelineHandler(1)}">
                                                        <p v-html="courseDate(course.startDate, course.endDate)"></p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <!--                                        課程地點-->
                                        <li class="info-item">
                                            <h5 class="item-title">
                                                <img src="../assets/images/icons/circle_address.svg" class="icon" alt="課程地點"/>
                                                課程地點
                                            </h5>
                                            <div class="item-content">
                                                <p>
                                                    <span class="primary-text">羽潮羽球運動休閒館-永康館</span>•710台南市永康區中正南路592巷68號
                                                </p>
                                            </div>
                                        </li>
                                        <!--                                        活動費用與報名-->
                                        <li class="info-item">
                                            <h5 class="item-title">
                                                <img src="../assets/images/icons/circle_cost.svg" class="icon" alt="活動費用與報名"/>
                                                活動費用與報名
                                            </h5>
                                            <div class="item-content">
                                                <ul class="ticket-type-list">
                                                    <li>
                                                        <p>
                                                            【優惠票】<span class="primary-text">NT$ 3,799/人</span>
                                                            <span style="text-decoration: line-through">(定價 NT$ 7,500/人)</span>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            【團體票】團體票(3人以上) ：<span class="primary-text">NT$ 10,000/3人</span> (請於表單內填寫共同報名者)
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            【平日下午加購價】<span class="primary-text">NT$ 500/人/5天</span>，需下午來接小孩，請勾選下午加購價，可協助照顧小朋友至16:00
                                                        </p>
                                                    </li>
                                                </ul>
                                                <p>
                                                    名額<span class="primary-text">人數8名</span>，報名採線上表單填寫或電話預約制•<span class="primary-text">耀迅 林先生 0958-175798</span>
                                                </p>
                                            </div>
                                        </li>
                                        <!--                                        報名截止日-->
                                        <li class="info-item">
                                            <h5 class="item-title">
                                                <img src="../assets/images/icons/circle_date.svg" class="icon" alt="報名截止日"/>
                                                報名截止日
                                            </h5>
                                            <div class="item-content">
                                                <p>
                                                    <template v-if="Number($route.params.courseId) === 1">2024-06-14 (五)</template>
                                                    <template v-else>{{ deadlineHandler() }} ({{ findWeek(deadlineHandler()) }})</template>
                                                    或線上報名額滿為止
                                                </p>
                                            </div>
                                        </li>
                                    </ol>
                                    <!--                                    其他備註-->
                                    <p class="text-justify font-weight-500 remark">
                                        其他備註: 本活動年齡以6-18歲為主，為確保訓練營教學品質，每梯每班以6人以上(含)進行團體課程開班(一個教練帶6位學生)，如有其他因素再行通知。
                                    </p>
                                </section>
                                <!--                                課程介紹-->
                                <section class="introduce-section">
                                    <h4 class="section-title">課程介紹<span class="en-title">/ Introduce</span></h4>
                                    <!--                                    課程大綱-->
                                    <section class="outline-section">
                                        <h6 class="sub-title">
                                            <info-icon/>
                                            課程大綱
                                        </h6>
                                        <div class="sub-content">
                                            <p class="content-title">羽球運動科技再進化！</p>
                                            <p class="content-description">智慧羽球拍也能感測羽球選手的揮拍軌跡、速度，甚至是殺球次數都能一覽無遺。由於隨著各種新興數位技術的興起，賦予運動產業新的樣貌，不再侷限傳統的運動科技思維，而逐漸拓展為智慧化的科技運動。</p>
                                            <div class="outline-wrapper">
                                                <img src="@/assets/images/outline_01.png" class="outline-img" alt="課程大綱圖片_1"/>
                                                <img src="@/assets/images/outline_02.png" class="outline-img" alt="課程大綱圖片_2"/>
                                            </div>
                                        </div>
                                    </section>
                                    <!--                                    課程特色-->
                                    <section class="features-section">
                                        <h6 class="sub-title">
                                            <info-icon/>
                                            課程特色
                                        </h6>
                                        <div class="sub-content-wrapper">
                                            <div>
                                                <div class="sub-content">
                                                    <p class="content-title">科技智能設備推展民眾多元運動體驗</p>
                                                    <p class="content-description">
                                                        本次透過在羽潮羽球館推動『導入科技智能設備推展民眾多元運動體驗』，結合羽潮羽球中心之運動量能，有別於一般運動體驗
                                                    </p>
                                                    <p class="content-description">
                                                        應用科技導入可即時回饋相關運動數據，並藉由系統紀錄運動成果，透過科學及數據化之方式，紀錄學員的成長軌跡，以優化民眾運動體驗，除了讓參與學員能得到羽球運動數據，這次也將導入影像串流系統，於活動結束後提供給學員。
                                                    </p>
                                                </div>
                                                <div class="sub-content">
                                                    <p class="content-title">活動特點</p>
                                                    <ul class="content-description features-list">
                                                        <li class="features-item">應用科技導入即時回饋相關運動數據，並系統紀錄運動成果</li>
                                                        <li class="features-item">透過科學及數據化方式紀錄學員的成長軌跡，優化民眾運動體驗</li>
                                                        <li class="features-item">參與學員能得到羽球運動數據，同時將導入影像串流系統</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <img src="@/assets/images/features_01.png" class="features-img" alt="課程特色圖片_1"/>
                                        </div>
                                        <!--                                        為什麼選擇我們-->
                                        <div class="sub-content-wrapper">
                                            <div class="sub-content">
                                                <p class="content-title">為什麼選擇我們</p>
                                                <p class="content-description">
                                                    (1)小朋友可了解自己的技術水平和潛力，激發對羽毛球的興趣和動力，提高技術和體能水平，培養自信心和毅力。
                                                </p>
                                                <different-table/>
                                                <p class="content-description mb-2">
                                                    (2)深獲小朋友喜愛
                                                </p>
                                                <img src="@/assets/images/description_img.png" class="w-100" alt="112年第2學期後甲學生性質會饋"/>
                                            </div>
                                        </div>
                                    </section>
                                    <!--                                    課程套組介紹-->
                                    <section class="set-section">
                                        <h6 class="sub-title">
                                            <info-icon/>
                                            課程套組介紹
                                        </h6>
                                        <!--                                        班級制-->
                                        <div class="class-system">
                                            <p class="content-title">營隊課程</p>
                                            <p class="content-description">
                                                將視課程進度安排授課單元，未以“括號”標註則代表該技術單元課程包含正反手
                                            </p>
                                            <div class="d-flex flex-column flex-sm-row align-items-center set-card">
                                                <img src="@/assets/images/set_img.png" alt="夏令營團課班"/>
                                                <div class="card-content">
                                                    <p class="card-title">夏令營團課班</p>
                                                    <div class="card-description">單元共計15小時(五天) 課程概括為</div>
                                                    <div class="d-flex flex-wrap align-items-center card-tag">
                                                        <p class="tag-text">熱身活動</p>
                                                        <p class="tag-text">教學活動</p>
                                                        <p class="tag-text">綜合練習</p>
                                                        <p class="tag-text">AI智慧羽球拍運用</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--                                        課程內容-->
                                        <div class="course-content">
                                            <p class="content-title">課程內容</p>
                                            <course-table/>
                                            <p class="primary-text">為確保因材施教，課程內容及實際授課內容，將依年紀彈性調整。</p>
                                        </div>
                                    </section>
                                    <!--                                    場地介紹-->
                                    <section class="venue-section">
                                        <h6 class="sub-title">
                                            <info-icon/>
                                            場地介紹
                                        </h6>
                                        <div class="sub-content-wrapper">
                                            <div class="sub-content">
                                                <p class="content-title">羽潮羽球運動休閒館-永康館</p>
                                                <p class="content-description">
                                                    位於永康中正南路的羽球館，使用BWF世界羽聯認證的勝利比賽級羽球地墊，場館挑高11米的比賽級羽球館，24小時的營業時間，全館冷氣開放，提供完整的衛浴設備、吧檯和用餐區，場地後方則有廣達800坪的停車場方便客戶停車。
                                                </p>
                                                <ul class="venue-list">
                                                    <li class="venue-item">
                                                        <a href="#" class="link">官方Facebook粉絲團</a>
                                                    </li>
                                                    <li class="venue-item">地址: 710台南市永康區中正南路592巷68號</li>
                                                    <li class="venue-item">營業時間: 24 小時營業</li>
                                                    <li class="venue-item">電話號碼: 0911 035 889</li>
                                                </ul>
                                                <a href="https://maps.app.goo.gl/6JNyAA8iCzEpG5rt7" class="btn secondary-btn" target="_blank">開啟Google Map</a>
                                            </div>
                                            <img src="@/assets/images/venue.png" class="venue-img" alt="羽潮羽球運動休閒館-永康館"/>
                                        </div>
                                    </section>
                                </section>
                                <!--                                注意事項-->
                                <section class="precautions-section">
                                    <h4 class="section-title">注意事項<span class="en-title">/ Precautions</span></h4>
                                    <section class="other-precautions-section">
                                        <h6 class="sub-title">
                                            <info-icon/>
                                            其他注意事項與備註
                                        </h6>
                                        <!--                                        停課相關規定-->
                                        <div class="sub-content">
                                            <p class="content-title">停課相關規定</p>
                                            <p class="content-description">
                                                因疫情或其他不可逆之情形導致場館關閉，或其他特殊狀況影響訓練營辦理，將以簡訊或電話通知，未接獲任何通知時請依營隊上課時間準時至現場報到。若課程日期逢天災(如颱風、地震、豪雨等)請依照行政院人事行政總處或市政府公告，本團隊不另行通知；後續調課或補課規範以本團隊公告訊息為準。
                                            </p>
                                        </div>
                                        <!--                                        退費規定-->
                                        <div class="sub-content">
                                            <p class="content-title">退費規定</p>
                                            <span class="d-block refund-remind">報名後因故不克參加者，請持發票正本辦理退費：</span>
                                            <ul class="content-description precautions-list">
                                                <li v-for="(item,index) in refundList"
                                                    :key="`refund_${index+1}`"
                                                    class="precautions-item">
                                                    {{ item }}
                                                </li>
                                            </ul>
                                        </div>
                                        <!--                                        注意事項-->
                                        <div class="sub-content">
                                            <p class="content-title">注意事項</p>
                                            <ul class="precautions-list">
                                                <li v-for="(item,index) in precautionsList"
                                                    :key="`precautions_${index+1}`"
                                                    class="precautions-item">
                                                    {{ item }}
                                                </li>
                                            </ul>
                                        </div>
                                    </section>
                                </section>
                            </article>
                            <!--                            右側資訊欄-->
                            <article class="other-wrapper">
                                <!--                                費用與資訊-->
                                <section v-if="!isExpired" class="section-card">
                                    <div class="card-title-wrapper">
                                        <p class="card-title cost-title">
                                            費用與資訊<span class="primary-text">【優惠票】</span>
                                        </p>
                                        <p class="cost-text">
                                            <span>NT$ 3,799 / </span>人
                                        </p>
                                    </div>
                                    <div class="card-content course-info-content">
                                        <p class="content-title">{{ course.name }}</p>
                                        <p class="countdown-text">
                                            <span class="d-inline-block tag">招生中</span>
                                            開課倒數
                                            <span class="primary-text">{{ countdown(course.startDate, 'day') }}</span> 天
                                            <span class="primary-text">{{ countdown(course.startDate, 'hours') }}</span> 時
                                            <span class="primary-text">{{ countdown(course.startDate, 'minute') }}</span> 分
                                        </p>
                                        <div class="countdown-line">
                                            <div class="line" :style="process(course.startDate)"></div>
                                        </div>
                                        <ul class="course-info-list">
                                            <li class="course-info-item">
                                                <category-icon class="icon"/>
                                                羽球課程
                                            </li>
                                            <li class="course-info-item">
                                                <calender-icon class="icon"/>
                                                {{ course.startDate }} ~ {{ course.endDate }}
                                            </li>
                                            <li class="course-info-item">
                                                <address-icon class="icon"/>
                                                710台南市永康區中正南路592巷68號
                                            </li>
                                            <li class="course-info-item">
                                                <date-icon class="icon"/>
                                                報名時間至
                                                <template v-if="Number($route.params.courseId) === 1">2024-06-14</template>
                                                <template v-else>{{ deadlineHandler() }}</template>
                                                止
                                            </li>
                                        </ul>
                                        <ul class="d-flex flex-wrap align-items-center course-tag-list">
                                            <li class="course-tag-item">TAG</li>
                                            <li v-for="(item,index) in courseTagList"
                                                :key="`course_tag_${index}`"
                                                class="course-tag-item">
                                                <p class="course-tag-text">{{ item }}</p>
                                            </li>
                                        </ul>
                                        <p class="d-flex align-items-center remind">
                                            <info-icon class="icon"/>
                                            本課程採用線上表單填寫或電話報名
                                        </p>
                                        <div class="d-flex flex-wrap align-items-center operate">
                                            <button type="button" class="btn gray-dark-btn" @click="comingSoon">
                                                <star-icon/>
                                                收藏
                                            </button>
                                            <button type="button" class="btn secondary-btn" @click="registrationModal.show = true">
                                                <span>＋</span>我要報名
                                            </button>
                                        </div>
                                    </div>
                                </section>
                                <!--                                講師陣容-->
                                <section class="section-card">
                                    <div class="card-title-wrapper">
                                        <p class="card-title">講師陣容</p>
                                    </div>
                                    <div class="card-content lecturer-content">
                                        <div class="d-flex flex-wrap align-items-center lecturer">
                                            <img :src="lecturerList[0].avatar" class="lecturer-avatar" alt="徐尉泰 教練"/>
                                            <p class="lecturer-name">{{ lecturerList[0].name }} 教練</p>
                                        </div>
                                        <p class="card-description">
                                            {{ lecturerList[0].description }}
                                        </p>
                                        <p v-if="lecturerList.length > 1" class="other-lecturer">...與其他{{ lecturerList.length - 1 }}位教練</p>
                                        <button type="button" class="btn secondary-btn w-100" @click="lecturerModal.show = true">查看更多</button>
                                    </div>
                                </section>
                                <!--                                海報文宣-->
                                <!--                                <section class="section-card">-->
                                <!--                                    <div class="card-title-wrapper">-->
                                <!--                                        <p class="card-title">海報文宣</p>-->
                                <!--                                    </div>-->
                                <!--                                    <div class="card-content">-->
                                <!--                                        <button type="button" class="btn poster-btn" @click="posterModal.show = true">-->
                                <!--                                            <img src="@/assets/images/poster.png" class="w-100 poster-img" alt="智慧羽球團體課程招生中"/>-->
                                <!--                                        </button>-->
                                <!--                                    </div>-->
                                <!--                                </section>-->
                                <!--                                系列課程推薦-->
                                <section v-if="recommendCourse.length !== 0" class="section-card">
                                    <div class="card-title-wrapper">
                                        <p class="card-title">系列課程推薦</p>
                                    </div>
                                    <div v-for="(item,index) in recommendCourse"
                                         :key="`recommend_${index}`"
                                         class="card-content recommend-content">
                                        <img src="@/assets/images/poster_banner.png?v=1" class="w-100 poster-img" alt="系列課程推薦"/>
                                        <p class="content-title">{{ item.name }}</p>
                                        <p class="card-description">
                                            {{ item.description }}
                                        </p>
                                        <p class="countdown-text">
                                            <span class="d-inline-block tag">招生中</span>
                                            開課倒數
                                            <span class="primary-text">{{ countdown(item.startDate, 'day') }}</span> 天
                                            <span class="primary-text">{{ countdown(item.startDate, 'hours') }}</span> 時
                                            <span class="primary-text">{{ countdown(item.startDate, 'minute') }}</span> 分
                                        </p>
                                        <div class="countdown-line">
                                            <div class="line" :style="process(item.startDate)"></div>
                                        </div>
                                        <div class="d-flex flex-wrap align-items-center operate">
                                            <button type="button" class="btn gray-dark-btn" @click="comingSoon">
                                                <star-icon/>
                                                收藏
                                            </button>
                                            <router-link :to="`/course-introduction/${item.ladder}`"
                                                         class="btn outline-secondary-btn"
                                                         target="_blank">
                                                <img src="@/assets/images/icons/link_blank.svg" class="icon" alt="查看詳情"/>
                                                查看詳情
                                            </router-link>
                                        </div>
                                    </div>
                                </section>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <video-modal v-model="videoModal.show"/>
        <registration-modal v-model="registrationModal.show"/>
        <lecturer-modal v-model="lecturerModal.show" :lecturer-list="lecturerList"/>
        <!--        <poster-modal v-model="posterModal.show"/>-->
    </main>
</template>
<script>
import AddressIcon from '@/components/Svg/AddressIcon.vue';
import Breadcrumb from '@/components/Common/Breadcrumb.vue';
import Courses from '@/components/CourseIntroduction/courses';
import CourseTable from '@/components/CourseIntroduction/CourseTable.vue';
import CategoryIcon from '@/components/Svg/CategoryIcon.vue';
import CalenderIcon from '@/components/Svg/CalenderIcon.vue';
import DateIcon from '@/components/Svg/DateIcon.vue';
import DifferentTable from '@/components/CourseIntroduction/DifferentTable.vue';
import LecturerModal from '@/components/CourseIntroduction/LecturerModal.vue';
import InfoIcon from '@/components/Svg/InfoIcon.vue';
import PosterModal from '@/components/CourseIntroduction/PosterModal.vue';
import RegistrationModal from '@/components/CourseIntroduction/RegistrationModal.vue';
import Share from '@/components/Common/Share.vue';
import StarIcon from '@/components/Svg/StarIcon.vue';
import TitleTag from '@/components/Common/TitleTag.vue';
import VideoModal from '@/components/CourseIntroduction/VideoModal.vue';

export default {
    name: 'CourseIntroduction',
    components: {
        AddressIcon,
        Breadcrumb,
        CourseTable,
        CategoryIcon,
        CalenderIcon,
        DateIcon,
        DifferentTable,
        LecturerModal,
        InfoIcon,
        PosterModal,
        RegistrationModal,
        Share,
        StarIcon,
        TitleTag,
        VideoModal
    },
    computed: {
        course() {
            const findCourse = _.find(Courses, item => item.ladder === Number(this.$route.params.courseId));
            return findCourse ? findCourse : {};
        },
        isExpired() {
            const today = this.$moment();
            return today.isAfter(this.$moment(this.course.startDate));
        },
        recommendCourse() {
            const today = this.$moment();
            const filterCourse = _.filter(Courses, item => item.ladder !== Number(this.$route.params.courseId) && !today.isAfter(item.startDate));
            return filterCourse;
        }
    },
    data() {
        return {
            videoModal: {
                show: false
            },
            registrationModal: {
                show: false
            },
            lecturerModal: {
                show: false
            },
            posterModal: {
                show: false
            },
            refundList: [
                '於開課日前三十一日提出申請，扣除報名費10%之行政作業費以及贈品費$400。',
                '於開課日前二十一日至三十日提出申請，扣除報名費20%之行政作業費以及贈品費$400。',
                '於開課日前二日至二十日前提出申請，扣除報名費30%之行政作業費以及贈品費$400。',
                '於開課日前一日提出申請，扣除報名費40%之行政作業費以及贈品費$400。',
                '於開課日起未達課程三分之一(兩日，含開課日)，扣除報名費50%之行政作業費以及贈品費$400。',
                '開課滿三分之一(三日，含開課日)，一律不予退費。',
                '若有開立公司統一編號者，請於退費時攜帶公司大小章或公司統一發票章辦理，否則不予辦理退費。',
                '如有傷病狀況辦理退費，需出示就醫紀錄或診斷證明，可全額退費。',
                '若因疫情或其餘不可抗力因素取消課程，自公告起一個月以內，憑發票正本辦理全額退費。',
                '如使用匯款付費，退費作業時間最多為14個工作天，不包含國定假日以及例假日。'
            ],
            precautionsList: [
                '完成訓練營報名程序時，視為同意訓練營各項規範，包含同意學員於營隊期間授權團隊官方使用其肖像權，如營隊現場平面拍攝與動態攝影，並於團隊官方網站及社群平台上架等等。',
                '團隊保留調整出席教練名單與營隊場地之權力。',
                '因安全與防疫因素考量，請學員務必穿著運動鞋及短褲，並自備水壺及羽球拍，服裝不符合規定者將由現場人員聯繫家長做更換；現場教練有權因安全考量調整服裝不符合規定之學員該日上課內容；上述情形恕不構成退費依據。',
                '人數額滿時或人數不足時，將直接順延至下一期，毋須重新報名，將於開課前以簡訊通知，未接獲通知表示延至下一期，請勿自行前來上課。'
            ],
            courseTagList: ['初階羽球', '羽潮羽球館', 'AI智慧羽球'],
            lecturerList: [
                {
                    name: '杜彥澤',
                    avatar: require('@/assets/images/coach_01_avatar.png'),
                    images: [
                        require('@/assets/images/coach_01.png')
                    ],
                    description: '3年個教經驗，教學經驗約20例，球齡15年',
                    competitionExperience: [
                        '2023 全國大專校院羽球排名積分賽 年終總決賽 男雙 亞軍',
                        '2023 全國大專校院羽球排名積分賽 北區預賽 混雙 第4',
                        '2023 全國大專校院羽球排名積分賽 北區預賽 男雙 第5',
                        '2023 全國大專校院羽球排名積分賽 中區預賽 男雙 第5',
                        '2023 全國大專校院羽球排名積分賽 南區預賽 男雙 第5',
                        '113全大運一般男團南區預賽 亞軍',
                        '112全大運一般男團總決賽 第5',
                        '112全大運一般男團南區預賽 亞軍',
                        '2024彰師羽球錦標賽 乙組男子雙打 第5',
                        '2023 Taiwan羽球團體錦標賽 大專男子組 冠軍',
                        '2023 Taiwan羽球團體錦標賽 T2男女混合組 冠軍',
                        '112青年盃全國錦標賽 大專公開組男雙 冠軍',
                        '112青年盃全國錦標賽 大專一般組男雙 亞軍',
                        '112勁竹杯羽球邀請賽 一般男子團體 第3',
                        '2023羽球密碼個人英雄盃 大專組男雙 冠軍',
                        '2023 全國府城盃羽球公開賽 U25男雙 第3',
                        '2023金門全國分齡錦標賽 男子團體 亞軍',
                        '2023 李寧力與美羽球分及錦標賽 大專團體混合組 冠軍',
                        '2023 李寧力與美羽球分及錦標賽 大專一般組男雙 亞軍'
                    ]
                },
                {
                    name: '林憙岳',
                    images: [
                        require('@/assets/images/coach_03.jpg')
                    ],
                    description: '2年個教經驗',
                    competitionExperience: [
                        '2023 全國大專校院羽球排名積分賽 年終總決賽 女雙 第4',
                        '2023 全國大專校院羽球排名積分賽 中區預賽 女雙 第4',
                        '113全大運一般女團南區預賽 亞軍',
                        '112全大運一般女團總決賽 亞軍',
                        '112全大運一般女團南區預賽 冠軍',
                        '2023彰師羽球錦標賽 乙組混合雙打 冠軍',
                        '2023彰師羽球錦標賽 乙組女子雙打 冠軍',
                        '2023 Taiwan羽球團體錦標賽 大專女子組 亞軍',
                        '2023 Taiwan羽球團體錦標賽 T2男女混合組 冠軍',
                        '112勁竹盃羽球邀請賽 一般女子團體 第3',
                        '113勁竹盃羽球邀請賽 一般女子團體 第2',
                        '2023金門全國分齡錦標賽 女子團體 亞軍',
                        '2023 李寧力與美羽球分及錦標賽 大專團體混合組 冠軍',
                        '2023 李寧力與美羽球分及錦標賽 大專一般組女雙 冠軍',
                        '2023 李寧力與美羽球分及錦標賽 大專一般組混雙 冠軍',
                        '2023 承億盃全國羽球錦標賽 大專一般組混雙 冠軍',
                        '2024府城群岳盃 大專一般組女雙 冠軍'
                    ]
                },
                {
                    name: '蔡玉堃',
                    images: [
                        require('@/assets/images/coach_02.jpeg')
                    ],
                    description: '球齡約十年、教球經歷約三年（含成人、小孩）',
                    competitionExperience: [
                        '全大運一般男團南區亞軍'
                    ]
                },
                {
                    name: '莊筑安',
                    images: [
                        require('@/assets/images/coach_04.JPG')
                    ],
                    description: '屏縣里港國小南市裕文國小助教 / 多次個教經驗',
                    competitionExperience: [
                        '2022 全國大專校院羽球排名積分賽 南區預賽 女單 第3',
                        '2022 全國大專校院羽球排名積分賽 北區預賽 女單 第3',
                        '2022 全國大專校院羽球排名積分賽 總決賽 女單 第4',
                        '2023 全國大專校院羽球排名積分賽 南區預賽 女單 第5',
                        '113全大運一般女團南區預賽 亞軍',
                        '112全大運一般女團總決賽 亞軍',
                        '112全大運一般女團南區預賽 冠軍',
                        '111全大運一般女團南區預賽 冠軍',
                        '113勁竹盃羽球邀請賽 一般女子團體 第2'
                    ]
                }
            ]
        };
    },
    methods: {
        courseDate(startDate, endDate) {
            const formatStartDate = this.$moment(startDate, 'YYYY-MM-DD');
            const formatEndDate = this.$moment(endDate, 'YYYY-MM-DD');
            const startWeek = this.findWeek(formatStartDate);
            const endWeek = this.findWeek(formatEndDate);
            const diffDay = formatEndDate.diff(formatStartDate, 'days') + 1;

            return `2024/<span class="primary-text">${startDate.split('-')[1]}/${startDate.split('-')[2]}</span>(${startWeek}) 至 <span class="primary-text">${endDate.split('-')[1]}/${endDate.split('-')[2]}</span>(${endWeek}) AM. 9:00 - 12:00 訓練${diffDay}天`;
        },
        deadlineHandler() {
            const formatEndDate = this.$moment(this.course.endDate);

            return formatEndDate.subtract(15, 'days').format('YYYY-MM-DD');
        },
        findWeek(date) {
            const formatDate = this.$moment(date);

            return formatDate.format('dddd').split('星期')[1];
        },
        datelineHandler(ladder) {
            // 每梯次的最後日期
            const ladderLastDateList = ['2024/07/05', '2024/07/12', '2024/07/19'];
            const today = this.$moment(new Date, 'YYYY/MM/DD');
            const findLadderIndex = _.findIndex(ladderLastDateList, item => {
                return today.isSameOrBefore(this.$moment(item, 'YYYY/MM/DD'), 'day');
            });
            return findLadderIndex !== -1 ? findLadderIndex + 1 === ladder : false;
        },
        // 開課倒數
        countdown(date, type) {
            const today = this.$moment();
            const countdownDate = this.$moment(date, 'YYYY-MM-DD');
            const distance = this.$moment.duration(countdownDate.diff(today));

            switch(type) { //是負數就顯示0
                case 'day':
                    return Math.max(Math.floor(distance.asDays()), 0);
                case 'hours':
                    const hours = Math.max(Math.floor(distance.asHours()) % 24, 0);
                    return hours.toString().padStart(2, '0');
                case 'minute':
                    const minute = Math.max(Math.floor(distance.minutes()) % 60, 0);
                    return minute.toString().padStart(2, '0');
                default:
                    return 0;
            }
        },
        process(date) {
            const startDate = this.$moment('2024-04-01', 'YYYY-MM-DD');
            const endDate = this.$moment(date, 'YYYY-MM-DD');
            const currentDate = this.$moment();

            const totalDays = endDate.diff(startDate, 'days');
            const elapsedDays = currentDate.diff(startDate, 'days');
            const progressPercent = (elapsedDays / totalDays) * 100;

            return `width: ${progressPercent.toFixed(0) + '%'}`;
        }
    }
};
</script>
<style lang="scss" scoped>
$section-responsive-padding: 15px 20px;
@mixin content-border{
    padding-bottom: 40px;
    @media(max-width: 575px){
        padding-bottom: 30px;
    }
}
.banner-wrapper{
    background-image:    url('@/assets/images/banner.png');
    background-repeat:   no-repeat;
    background-size:     cover;
    background-position: center;
    padding:             100px 15px;
    @media (max-width: 575px){
        padding: 50px 15px;
    }
    .en-title{
        color:       var(--secondary-color);
        font-weight: bold;
        font-size:   40px;
        @media (max-width: 575px){
            font-size: 20px;
        }
    }
    .title{
        color:         white;
        font-weight:   bold;
        font-size:     50px;
        margin-bottom: 25px;
        @media (max-width: 575px){
            font-size:     25px;
            margin-bottom: 15px;
        }
    }
    .sub-title{
        color:          white;
        letter-spacing: 2px;
        font-size:      20px;
        gap:            5px;
        .icon{
            width: 25px;
        }
        @media (max-width: 575px){
            font-size: 16px;
        }
    }
}
.content-wrapper{
    padding: 25px 0;
    @media (max-width: 575px){
        padding: 15px 0;
    }
    .header-wrapper{
        margin-bottom: 10px;
        .share{
            padding-bottom: 20px;
        }
    }
    .flex-wrapper{
        display:   flex;
        flex-wrap: wrap;
        gap:       20px;
    }
    .course-wrapper{
        width: calc(75% - 10px);
        @media(max-width: 991px){
            width: 100%;
        }
    }
    .other-wrapper{
        width: calc(25% - 10px);
        @media(max-width: 991px){
            width: 100%;
        }
    }
}
.course-wrapper{
    border:        1px solid var(--gray-dark-color);
    border-radius: 10px;
    .section-title{
        margin-bottom: 20px;
        color:         var(--secondary-dark-color);
        font-size:     32px;
        .en-title{
            font-size:   20px;
            font-family: 'Arial', sans-serif;
            margin-left: 5px;
            font-weight: bold;
        }
    }
    .sub-title{
        padding:          15px 10px;
        background-color: #D8EFFC;
        font-size:        18px;
        color:            var(--secondary-color);
        display:          flex;
        align-items:      center;
        gap:              5px;
        margin-bottom:    20px;
    }
    .content-title{
        padding-left:  10px;
        border-left:   5px solid var(--secondary-color);
        margin-bottom: 10px;
        font-weight:   500;
        font-size:     18px;
    }
    .content-description{
        margin-bottom: 30px;
        text-align:    justify;
        @media(max-width: 575px){
            margin-bottom: 15px;
        }
    }
    .video-section{
        width:         100%;
        height:        320px;
        overflow:      hidden;
        border-radius: 10px 10px 0 0;
        @media (max-width: 1439px){
            height: auto;
        }
        @media(max-width: 575px){
            height: 200px;
        }
        img{
            object-fit: cover;
        }
    }
    .info-section{
        padding: 30px 40px 20px;
        @media(max-width: 991px){
            padding: $section-responsive-padding;
        }
        .section-title{
            margin-bottom: 10px;
        }
        .info-description{
            font-weight:   500;
            margin-bottom: 30px;
        }
        .info-list{
            margin-bottom: 30px;
            @media (max-width: 575px){
                margin-bottom: 15px;
            }
            .info-item{
                margin-bottom: 30px;
                @media (max-width: 575px){
                    margin-bottom: 15px;
                }
                &:last-child{
                    margin-bottom: 0;
                }
            }
            .item-title{
                font-size:     20px;
                margin-bottom: 5px;
                min-height:    40px;
                display:       flex;
                align-items:   center;
                column-gap:    20px;
                row-gap:       10px;
                @media (max-width: 575px){
                    column-gap: 10px;
                }
                .icon{
                    width: 40px;
                    @media(max-width: 575px){
                        width: 35px;
                    }
                }
            }
            .item-content{
                font-weight:  500;
                padding-left: 60px;
                text-align:   justify;
                @media(max-width: 575px){
                    padding-left: 0;
                }
                .ticket-type-list{
                    margin-bottom: 4px;
                    line-height:   30px;
                }
                p{
                    color: #505050;
                }
            }
            .dateline-list .dateline-item{
                position:      relative;
                margin-bottom: 5px;
                &:last-child{
                    margin-bottom: 0;
                }
                p{
                    color: #C8C8C8;
                }
                &.active{
                    &:before{
                        content:   '▶';
                        font-size: 10px;
                        position:  absolute;
                        left:      0;
                        top:       4.5px;
                        color:     var(--primary-color);
                    }
                    p{
                        color: #505050;
                    }
                }
            }
        }
        .remark{
            @include content-border;
            border-bottom: 1px solid var(--gray-dark-color);
        }
    }
    //課程介紹
    .introduce-section{
        padding: 20px 40px;
        @media(max-width: 575px){
            padding: $section-responsive-padding;
        }
        //課程大綱
        .outline-section{
            margin-bottom: 70px;
            @media(max-width: 575px){
                margin-bottom: 35px;
            }
            .outline-wrapper{
                display:               grid;
                grid-template-columns: repeat(2, 1fr);
                gap:                   15px;
                @media (max-width: 575px){
                    grid-template-columns: repeat(1, 1fr);
                }
                .outline-img{
                    width:         100%;
                    border-radius: 10px;
                }
            }
        }
        //課程特色
        .features-section{
            margin-bottom: 40px;
            @media(max-width: 575px){
                margin-bottom: 20px;
            }
            .sub-content-wrapper{
                display: flex;
                gap:     20px;
                width:   100%;
                @media(max-width: 1440px){
                    flex-wrap: wrap;
                }
                @media(max-width: 1023px){
                    margin-bottom: 30px;
                }
                &:last-child{
                    @media(max-width: 1023px){
                        margin-bottom: 0;
                    }
                }
                .sub-content{
                    width: 100%;
                }
            }
            .features-img{
                width:     100%;
                max-width: 400px;
                margin:    0 auto;
                @media(max-width: 575px){
                    max-width: 300px;
                }
            }
            .features-list{
                padding-left: 25px;
                list-style:   disc;
                .features-item{
                    color:         var(--primary-color);
                    margin-bottom: 10px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
        //課程套組介紹 - 開班制
        .set-section{
            margin-bottom: 50px;
            @media (max-width: 575px){
                margin-bottom: 25px;
            }
            .class-system{
                margin-bottom: 30px;
                @media (max-width: 575px){
                    margin-bottom: 15px;
                }
            }
            .content-description{
                margin-bottom: 20px;
            }
            .set-card{
                background:    transparent linear-gradient(180deg, #FFFFFF 0%, #DFF5E2 100%) 0 0 no-repeat padding-box;
                border:        1px solid #A8D66B;
                border-radius: 10px;
                padding:       20px 25px;
                column-gap:    25px;
                row-gap:       10px;
                @media(max-width: 575px){
                    padding: 15px 20px;
                }
                .card-title{
                    color:         #5DA515;
                    font-weight:   500;
                    margin-bottom: 5px;
                    font-size:     18px;
                }
                .card-description{
                    margin-bottom: 15px;
                    font-size:     14px;
                    font-weight:   500;
                }
                .card-tag{
                    gap: 10px;
                }
                .tag-text{
                    padding:          5px 15px;
                    border-radius:    50px;
                    background-color: #57AE47;
                    color:            white;
                }
            }
        }
        //場地介紹
        .venue-section{
            .sub-content-wrapper{
                @include content-border;
                display:               grid;
                grid-template-columns: repeat(2, 1fr);
                gap:                   30px;
                border-bottom:         1px solid var(--gray-dark-color);
                @media(max-width: 991px){
                    grid-template-columns: repeat(1, 1fr);
                }
            }
            .venue-list{
                padding-left:  25px;
                list-style:    disc;
                margin-bottom: 40px;
                @media(max-width: 575px){
                    margin-bottom: 20px;
                }
            }
            .venue-item{
                color:         var(--primary-color);
                margin-bottom: 10px;
                &:last-child{
                    margin-bottom: 0;
                }
                .link{
                    color:           var(--primary-color);
                    text-decoration: underline;
                }
            }
            .secondary-btn{
                padding:       10px 30px;
                border-radius: 50px;
                font-size:     14px;
            }
            .venue-img{
                width:      100%;
                object-fit: cover;
                max-width:  500px;
                margin:     0 auto;
            }
        }
    }
    .precautions-section{
        padding: 20px 40px 30px;
        @media(max-width: 575px){
            padding: $section-responsive-padding;
        }
        .content-description{
            color: #505050;
        }
        .refund-remind{
            margin-bottom: 10px;
            color:         #505050;
        }
        .precautions-list{
            padding-left: 25px;
            list-style:   decimal;
            .precautions-item{
                margin-bottom: 10px;
                color:         #505050;
                text-align:    justify;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
}
.other-wrapper{
    .section-card{
        border-radius: 10px;
        border:        1px solid var(--gray-dark-color);
        overflow:      hidden;
        margin-bottom: 20px;
        &:last-child{
            margin-bottom: 0;
        }
        .card-title-wrapper{
            padding:          15px 20px;
            background-color: var(--gray-color);
        }
        .card-title{
            font-size:     14px;
            font-weight:   500;
            margin-bottom: 0;
            &.cost-title{
                margin-bottom: 10px;
            }
        }
        .cost-text{
            color:       var(--primary-color);
            font-size:   30px;
            font-weight: bold;
            span{
                font-family: Arial, sans-serif;
                font-size:   36px;
            }
        }
        .card-content{
            padding: 20px;
            .content-title{
                font-size:     20px;
                margin-bottom: 10px;
                font-weight:   500;
            }
            .countdown-text{
                font-weight:   500;
                margin-bottom: 10px;
                .tag{
                    padding:          5px;
                    background-color: var(--primary-color);
                    color:            white;
                    border-radius:    5px;
                    line-height:      1;
                    margin-right:     5px;
                }
            }
            .countdown-line{
                background-color: #EFF3F8;
                border-radius:    10px;
                width:            100%;
                height:           10px;
                margin-bottom:    25px;
                position:         relative;
                overflow:         hidden;
                .line{
                    position:         absolute;
                    left:             0;
                    top:              0;
                    height:           100%;
                    background-color: var(--secondary-color);
                }
            }
            .btn{
                padding:         10px 25px;
                display:         flex;
                align-items:     center;
                justify-content: center;
                gap:             4px;
                border-radius:   50px;
                white-space:     nowrap;
            }
            .poster-btn{
                padding:       0;
                border-radius: 0;
            }
            .poster-img{
                display:      block;
                margin-left:  auto;
                margin-right: auto;
                max-width:    350px;
            }
        }
    }
    //費用與資訊
    .course-info-content{
        .course-info-list{
            margin-bottom: 25px;
            color:         var(--secondary-dark-color);
            .course-info-item{
                display:       flex;
                align-items:   center;
                column-gap:    10px;
                row-gap:       5px;
                margin-bottom: 10px;
                font-weight:   500;
                font-size:     14px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
            .icon{
                color: var(--secondary-dark-color);
                width: 15px;
            }
        }
        .course-tag-list{
            margin-bottom: 70px;
            color:         var(--secondary-dark-color);
            @media(max-width: 575px){
                margin-bottom: 35px;
            }
            .course-tag-item{
                margin-right: 10px;
                font-weight:  500;
                font-size:    14px;
                &:first-child{
                    margin-right: 5px;
                }
                &:last-child{
                    margin-right: 0;
                }
            }
            .course-tag-text{
                position: relative;
                &:before{
                    content: '#'
                }
            }
        }
        .remind{
            color:         var(--primary-color);
            font-weight:   500;
            margin-bottom: 15px;
            .icon{
                margin-right: 5px;
            }
        }
        .operate{
            gap: 10px;
            .gray-dark-btn{
                flex: 1;
            }
            .secondary-btn{
                flex: 2;
            }
        }
    }
    //講師陣容
    .lecturer-content{
        .lecturer{
            column-gap:    20px;
            row-gap:       10px;
            margin-bottom: 20px;
            .lecturer-avatar{
                width:         80px;
                height:        80px;
                border-radius: 50%;
                overflow:      hidden;
            }
            .lecturer-name{
                font-size:   20px;
                font-weight: 500;
            }
        }
        .card-description{
            color:         #646464;
            font-size:     14px;
            margin-bottom: 20px;
        }
        .other-lecturer{
            font-size:     14px;
            font-weight:   500;
            margin-bottom: 10px;
        }
    }
    //系列課程推薦
    .recommend-content{
        .poster-img{
            margin-bottom: 15px;
        }
        .card-description{
            color:         #646464;
            font-size:     14px;
            margin-bottom: 30px;
            font-weight:   500;
        }
        .operate{
            gap: 10px;
            .gray-dark-btn{
                flex: 1;
            }
            .outline-secondary-btn{
                flex: 2;
                .icon{
                    width:      15px;
                    margin-top: 2px;
                }
            }
        }
    }
}
</style>