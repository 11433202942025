import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import {BootstrapVue} from 'bootstrap-vue';
import _ from 'lodash';
import VueSocialSharing from 'vue-social-sharing';
import VueClipboard from 'vue-clipboard2';
import VueScrollTo from 'vue-scrollto';
import VeeValidate from 'vee-validate';
import {defaultMixins} from '@/mixins/default';
import moment from 'moment';
import http from '@/config/http';
import '@/assets/css/main.scss';

Vue.config.productionTip = false;
moment.locale('zh-TW');

Vue.prototype.$moment = moment;
Vue.prototype._ = _;
Vue.prototype.$http = http;

Vue.use(BootstrapVue);
Vue.use(VueSocialSharing);
Vue.use(VeeValidate);
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.use(VueScrollTo, {
    container: 'body',
    duration: 700,
    easing: 'ease',
    offset: -50
});

Vue.mixin(defaultMixins);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
