<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20.571" height="16" viewBox="0 0 20.571 16">
        <path d="M125.657-744,120-752l5.657-8h14.914v16Zm1.771-6.857a1.106,1.106,0,0,0,.814-.329,1.106,1.106,0,0,0,.329-.814,1.106,1.106,0,0,0-.329-.814,1.106,1.106,0,0,0-.814-.329,1.106,1.106,0,0,0-.814.329,1.106,1.106,0,0,0-.329.814,1.106,1.106,0,0,0,.329.814A1.106,1.106,0,0,0,127.429-750.857Zm4,0a1.106,1.106,0,0,0,.814-.329,1.106,1.106,0,0,0,.329-.814,1.106,1.106,0,0,0-.329-.814,1.106,1.106,0,0,0-.814-.329,1.106,1.106,0,0,0-.814.329,1.106,1.106,0,0,0-.329.814,1.106,1.106,0,0,0,.329.814A1.106,1.106,0,0,0,131.429-750.857Zm4,0a1.106,1.106,0,0,0,.814-.329,1.106,1.106,0,0,0,.329-.814,1.106,1.106,0,0,0-.329-.814,1.106,1.106,0,0,0-.814-.329,1.106,1.106,0,0,0-.814.329,1.106,1.106,0,0,0-.329.814,1.106,1.106,0,0,0,.329.814A1.106,1.106,0,0,0,135.429-750.857Z"
              transform="translate(-120 760)" fill="currentColor"/>
    </svg>
</template>
<script>
export default {
    name: 'CategoryIcon'
}
</script>