<template>
    <div class="mb-0 table-responsive">
        <table class="table">
            <thead>
            <tr>
                <th v-for="item in tableColumn" :key="item.dataName">
                    {{ item.name }}
                </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(dataItem,dataIndex) in tableData"
                :key="`row_${dataIndex}`">
                <td v-for="columnItem in tableColumn"
                    :key="`row_${dataIndex}_${columnItem.dataName}`">
                    <template v-if="!Array.isArray(dataItem[columnItem.dataName])">
                        {{ dataItem[columnItem.dataName] }}
                    </template>
                    <ul v-else class="course-list">
                        <li v-for="(listItem,listIndex) in dataItem[columnItem.dataName]"
                            :key="`${columnItem.dataName}_list_${listIndex}`">
                            {{ listItem }}
                        </li>
                    </ul>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
export default {
    name: 'CourseTable',
    data() {
        return {
            tableColumn: [
                {
                    name: '課程天數',
                    dataName: 'day'
                },
                {
                    name: '熱身活動',
                    dataName: 'warmUp'
                },
                {
                    name: '活動教學',
                    dataName: 'teaching'
                },
                {
                    name: '綜合練習',
                    dataName: 'practice'
                },
                {
                    name: 'AI智慧羽球拍應用',
                    dataName: 'application'
                }
            ],
            tableData: [
                {
                    day: '第1天',
                    warmUp: ['球感遊戲', '大小肌肉動態熱身活動'],
                    teaching: ['場地介紹', '基本握拍揮拍練習', '揮擊平球教學'],
                    practice: ['原地擊球練習', '動態擊球練習'],
                    application: ['輔助播放基本動作等影片介紹', '輔助教練掌握學員程度與進度', '記錄學習歷程']
                },
                {
                    day: '第2天',
                    warmUp: ['敏捷性活動', '跳躍性活動'],
                    teaching: ['發球技術-正手發前場短球', '前場回球技術-網前小球', '融入前場基本步伐'],
                    practice: ['正手發球技術練習', '原地網前小球回球練習', '動態網前小球回球練習', '分組分項循環練習'],
                    application: ['輔助教練掌握學員練習結果', '輔助教練分析與修正學員動作']
                },
                {
                    day: '第3天',
                    warmUp: ['反應性活動', '肌耐力活動'],
                    teaching: ['發球技術-正手發後場長球', '後場回球技術-正手高遠球', '融入後場基本步伐'],
                    practice: ['正手發球技術練習', '原地高遠球回球練習', '動態高遠球回球練習', '分組分項循環練習'],
                    application: ['輔助教練掌握學員練習量', '輔助教練分析學員動作表現']
                },
                {
                    day: '第4天',
                    warmUp: ['協調性活動', '平衡感活動'],
                    teaching: ['發球技術-反手發球', '前場回球技術-反手小球', '後場回球技術-反手長球', '全場跑動步伐練習'],
                    practice: ['反手發球技術練習', '反手擊球練習', '分組分項循環練習'],
                    application: ['輔助教練分析學員各項動作表現', '列印學習進度']
                },
                {
                    day: '第5天',
                    warmUp: ['團體熱身遊戲'],
                    teaching: ['單打、雙打技術介紹', '單打、雙打技術教學'],
                    practice: ['綜合技術多拍擊球練習', '實戰對打活動'],
                    application: ['各種技術學習驗收與列印成果', '頒發參與證書，贈送紀念品', '整理並將活動精彩紀錄分享給學員、家長及各社群媒體']
                }
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
//課程套組介紹 - 課程內容
.table{
    border-collapse: separate;
    th{
        background-color: var(--secondary-color);
        color:            white;
        padding:          10px;
        border:           0;
        white-space:      nowrap;
        word-break:       keep-all;
        font-size:        14px;
        font-weight:      500;
        &:first-child,
        &:last-child{
            text-align: center;
        }
        &:first-child{
            border-radius: 10px 0 0 0;
        }
        &:last-child{
            border-radius: 0 10px 0 0;
        }
    }
    tbody{
        td{
            padding:      10px 10px 20px 10px;
            border-right: 1px solid var(--secondary-color);
            font-size:    14px;
            font-weight:  500;
            color:        #3C3C3C;
            border-top:   0;
            max-width:    280px;
            @media(max-width: 1439px){
                white-space: nowrap;
                word-break:  keep-all;
                max-width:   100%;
            }
            &:first-child{
                text-align: center;
                color:      black;
            }
            &:first-child{
                border-left: 1px solid var(--secondary-color);
            }
        }
        tr:nth-child(even) td{
            background-color: #EFF3F8;
        }
        tr:last-child td{
            border-bottom: 1px solid var(--secondary-color);
            &:first-child{
                border-radius: 0 0 0 10px;
            }
            &:last-child{
                border-radius: 0 0 10px 0;
            }
        }
    }
    .course-list{
        padding-left: 6px;
        li::marker{
            content: '-';
        }
    }
}
</style>