<template>
    <div class="auth-wrapper" :class="system">
        <header class="position-relative header-wrapper">
            <template v-if="systemId === 1">
                <img :src="require('@/assets/images/victor_header.png')" class="h-100 w-100 header-img" alt=""/>
                <img src="@/assets/images/icons/victor_icon.svg" class="position-absolute header-icon" style="top:40%" alt=""/>
            </template>
            <template v-if="systemId === 2">
                <img :src="require('@/assets/images/uBadminton_header.png')" class="h-100 w-100 header-img" alt=""/>
                <img src="@/assets/images/icons/uBadminton_icon.svg" class="position-absolute w-100 h-100 header-icon" style="top:50%" alt=""/>
            </template>
        </header>
        <main class="container-fluid">
            <img v-if="systemId === 3" src="@/assets/images/logo.png" class="d-block mx-auto ykd-logo" alt="羽客多Logo"/>
            <section :class="{'ykd-wrapper':systemId === 3}">
                <h2 class="text-center title">忘記密碼</h2>
                <form class="form-wrapper">
                    <div class="form-column-group">
                        <label for="password" class="label-text">新密碼</label>
                        <div class="position-relative mb-1">
                            <input v-model="form.password"
                                   ref="password"
                                   :type="[passwordOpen ? 'text' : 'password']"
                                   name="password"
                                   class="form-control password-control"
                                   v-validate="{required:true,min:8,regex:'^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).+$'}"
                                   placeholder="請輸入密碼"/>
                            <button type="button" class="btn p-0 eye-btn" @click="passwordOpen = !passwordOpen">
                                <img v-if="passwordOpen" src="@/assets/images/icons/open_eye.svg" class="w-100 h-100"/>
                                <img v-else src="@/assets/images/icons/close_eye.svg" class="w-100 h-100"/>
                            </button>
                        </div>
                        <p v-show="errors.has('password:required')" class="validate-text">
                            <close-icon class="validate-icon"/>
                            <span>請輸入新密碼</span>
                        </p>
                        <p class="validate-text" :class="{'default':!errors.has('password:min')}">
                            <close-icon class="validate-icon"/>
                            <span>至少8個字元</span>
                        </p>
                        <p class="validate-text" :class="{'default':!errors.has('password:regex')}">
                            <close-icon class="validate-icon"/>
                            <span>需要包含大小寫英文字母以及數字</span>
                        </p>
                    </div>
                    <div class="form-column-group">
                        <label for="password" class="label-text">確認新密碼</label>
                        <div class="position-relative mb-1">
                            <input v-model="form.password_confirmation"
                                   :type="[confirmPasswordOpen ? 'text' : 'password']"
                                   name="confirmPassword"
                                   autocomplete="off"
                                   class="form-control password-control"
                                   data-vv-as="password"
                                   v-validate="'required|confirmed:password'"
                                   placeholder="請再次輸入新密碼"/>
                            <button type="button" class="btn p-0 eye-btn" @click="confirmPasswordOpen = !confirmPasswordOpen">
                                <img v-if="confirmPasswordOpen" src="@/assets/images/icons/open_eye.svg" class="w-100 h-100"/>
                                <img v-else src="@/assets/images/icons/close_eye.svg" class="w-100 h-100"/>
                            </button>
                        </div>
                        <span v-show="errors.has('confirmPassword:required')" class="validate-text">請再輸入一次密碼</span>
                        <span v-show="errors.has('confirmPassword:confirmed')" class="validate-text">密碼不符</span>
                    </div>
                    <button type="button" class="d-block mx-auto btn submit-btn" @click="submit">確定送出</button>
                </form>
            </section>
        </main>
    </div>
</template>
<script>
import CloseIcon from '@/components/Svg/CloseIcon.vue';

export default {
    name: 'Forgot',
    components: {
        CloseIcon
    },
    computed: {
        system() {
            const systemId = this.$route.query.system_id ? Number(this.$route.query.system_id) : null;
            return systemId !== null ? systemId === 1 ? 'victor' : systemId === 2 ? 'u-badminton' : 'ykd' : '';
        },
        systemId() {
            return this.$route.query.system_id ? Number(this.$route.query.system_id) : null;
        }
    },
    data() {
        return {
            form: {},
            passwordOpen: false,
            confirmPasswordOpen: false
        }
    },
    methods: {
        async submit() {
            const isPass = await this.validate();
            if(!isPass) return;

            await this.fetchResetPassword();
        },
        async fetchResetPassword() {
            try {
                const token = this.$route.query.token;

                const res = await this.$http.post('/reset', this.form, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                if(res.status === 200) {
                    if(confirm('密碼修改成功，請回到原系統登入')) {
                        window.close();
                    }
                }
            } catch(err) {
                alert('密碼修改失敗，請稍後再試');
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.auth-wrapper{
    background-color: #F3F3F3;
    flex-grow:        1;
    .header-wrapper{
        height:        200px;
        margin-bottom: 30px;
        .header-img{
            object-fit:      cover;
            object-position: bottom;
        }
        .header-icon{
            left:      50%;
            transform: translate(-50%, -50%);
            max-width: 300px;
        }
    }
    .ykd-wrapper{
        padding:          30px;
        background-color: white;
        border:           1px solid #DCDCDC;
        border-radius:    10px;
        width:            100%;
        max-width:        1200px;
        margin:           0 auto;
    }
    .ykd-logo{
        margin-bottom: 25px;
    }
    .title{
        margin-bottom: 40px;
        font-size:     24px;
        font-weight:   500;
    }
    .form-wrapper{
        width:     100%;
        max-width: 450px;
        margin:    0 auto;
        .form-column-group{
            display:        flex;
            flex-direction: column;
            margin-bottom:  15px;
            &:last-child{
                margin-bottom: 0;
            }
            .label-text{
                display:       inline-block;
                margin-bottom: 5px;
                margin-right:  0;
            }
        }
        .password-control{
            padding-right: 45px;
        }
        .eye-btn{
            display:         flex;
            align-items:     center;
            justify-content: center;
            width:           20px;
            height:          20px;
            position:        absolute;
            right:           15px;
            top:             50%;
            transform:       translateY(-50%);
        }
        .validate-text{
            display:     flex;
            align-items: center;
            gap:         2px;
            &.default{
                color: #787878;
            }
            .validate-icon{
                margin-top: 2px;
            }
        }
        .submit-btn{
            color: white;
        }
    }
}
.auth-wrapper.victor{
    .submit-btn{
        background-color: #0033A0;
    }
}
.auth-wrapper.u-badminton{
    .submit-btn{
        background-color: #248CAF;
    }
}
.auth-wrapper.ykd{
    .header-wrapper{
        height: 120px;
    }
    .submit-btn{
        background-color: var(--secondary-color);
        padding:          15px 70px;
        border-radius:    50px;
    }
}
</style>