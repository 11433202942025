<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19">
        <path d="M12,17.27,18.18,21l-1.64-7.03L22,9.24l-7.19-.61L12,2,9.19,8.63,2,9.24l5.46,4.73L5.82,21Z"
              transform="translate(-2 -2)" fill="currentColor"/>
    </svg>
</template>
<script>
export default {
    name: 'StarIcon'
}
</script>