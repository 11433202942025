<template>
    <b-navbar toggleable="lg" fixed="top">
        <div class="container">
            <!--                logo-->
            <b-navbar-brand href="/">
                <img src="@/assets/images/logo.png" class="w-100 logo-img" alt="羽客多"/>
            </b-navbar-brand>
            <!--                收合按鈕-->
            <b-navbar-toggle target="nav-collapse" class="order-1 order-lg-0"></b-navbar-toggle>
            <!--                nav-list-->
            <b-collapse id="nav-collapse" is-nav class="order-2 order-lg-0 justify-content-end">
                <b-navbar-nav class="nav-list">
                    <li v-for="(item,index) in linkList" :key="`link_${index}`" class="nav-item">
                        <router-link v-if="item.url !== '/'" :to="item.url" class="nav-link">{{ item.name }}</router-link>
                        <button v-else type="button" class="btn nav-link" @click="comingSoon">{{ item.name }}</button>
                    </li>
                </b-navbar-nav>
            </b-collapse>
            <!--                其他-->
            <b-navbar-nav class="ml-auto ml-lg-0 other-list">
                <li>
                    <button type="button" class="btn primary-linear-btn" @click="comingSoon">
                        <span class="d-none d-sm-block">使用者中心</span>
                        <img src="@/assets/images/icons/user.svg" class="d-block d-sm-none icon" alt="使用者中心"/>
                    </button>
                </li>
                <li>
                    <button type="button" class="btn primary-light-btn" @click="comingSoon">
                        <img src="@/assets/images/icons/shopping_cart.svg" class="icon" alt="購物車"/>
                    </button>
                </li>
                <li>
                    <button type="button" class="btn primary-light-btn" @click="comingSoon">
                        <img src="@/assets/images/icons/search.svg" class="icon" alt="搜尋"/>
                    </button>
                </li>
            </b-navbar-nav>
        </div>
    </b-navbar>
</template>
<script>
export default {
    name: 'HeaderView',
    data() {
        return {
            linkList: [
                {
                    name: '關於我們',
                    url: '/'
                },
                {
                    name: '最新消息',
                    url: '/'
                },
                {
                    name: '課程介紹',
                    url: '/course-introduction'
                },
                {
                    name: '產品開發',
                    url: '/'
                },
                {
                    name: '聯絡我們',
                    url: '/'
                }
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
.navbar{
    padding:          10px 15px 5px;
    background-color: white;
    border-bottom:    1px solid #E6E6E6;
    .navbar-brand{
        padding:         0;
        display:         flex;
        align-items:     center;
        justify-content: center;
        .logo-img{
            max-width: 200px;
            @media (max-width: 575px){
                max-width: 120px;
            }
        }
    }
    .navbar-toggler{
        border:  0;
        padding: 0;
        &:focus-visible{
            outline: none;
        }
    }
    .nav-list{
        column-gap:   30px;
        margin-right: 40px;
        @media(max-width: 1199px){
            column-gap:   8px;
            margin-right: 15px;
        }
        @media(max-width: 991px){
            margin-right: 0;
        }
        .nav-link{
            display:     inline-block;
            color:       black;
            font-weight: 500;
            position:    relative;
            padding:     12px 8px;
            text-align:  center;
            @media(max-width: 991px){
                padding: 6px 0;
            }
            &:before{
                content:          '';
                position:         absolute;
                bottom:           -5px;
                left:             50%;
                transform:        translateX(-50%);
                width:            30px;
                height:           5px;
                background-color: transparent;
                border-radius:    50px;
                @media (max-width: 991px){
                    content: none;
                }
            }
            &:hover,
            &.router-link-active{
                color: var(--primary-color);
            }
            &:hover::before,
            &.router-link-active:before{
                background-color: var(--primary-color);
            }
        }
    }
    .other-list{
        column-gap:     10px;
        align-items:    center;
        flex-direction: row;
        margin-right:   10px;
        .primary-linear-btn{
            display:         flex;
            align-items:     center;
            justify-content: center;
            padding:         12px 30px;
            border-radius:   50px;
            @media (max-width: 575px){
                width:         35px;
                height:        35px;
                border-radius: 50%;
                padding:       0;
            }
            &:hover{
                text-decoration: none;
            }
            .icon{
                width:  22px;
                height: 22px;
            }
        }
        .primary-light-btn{
            border-radius:   50%;
            width:           50px;
            height:          50px;
            padding:         0;
            display:         flex;
            align-items:     center;
            justify-content: center;
            @media (max-width: 575px){
                width:  35px;
                height: 35px;
            }
            .icon{
                width:  16px;
                height: 16px;
            }
        }
    }
}
</style>