<template>
    <div class="d-flex align-items-center share-wrapper">
        <p class="d-flex align-items-center font-roboto-700 title">
            <share-icon class="icon"/>
            SHARE
        </p>
        <ul class="d-flex align-items-center share-list">
            <li>
                <ShareNetwork
                    ref="fbRef"
                    network="facebook"
                    class="btn share-btn"
                    :url="ladderHandler()"
                    :title="shareTitle"
                    description="本次團體活動羽潮羽球館舉行，特色在於結合科技智能設備，與提供即時回饋運動數據，並紀錄學員成長，以科學數據優化運動體驗。同時，影像串流系統也將提供給學員，豐富活動體驗樂趣。"
                    @open="share('fbRef')">
                    <img src="@/assets/images/icons/facebook.svg" class="w-auto" alt="Facebook"/>
                </ShareNetwork>
            </li>
            <li>
                <ShareNetwork
                    ref="lineRef"
                    network="line"
                    class="btn share-btn"
                    :url="ladderHandler()"
                    :title="shareTitle"
                    description="本網站課程在於結合科技智能設備，與提供即時回饋運動數據，並紀錄學員成長，以科學數據優化運動體驗。同時，影像串流系統也將提供給學員，且提供完整的學習歷程，豐富大小朋友活動體驗樂趣。"
                    @open="share('lineRef')">
                    <img src="@/assets/images/icons/line.svg" class="w-auto" alt="Line"/>
                </ShareNetwork>
            </li>
            <li>
                <button type="button" class="btn share-btn" @click="copy">
                    <img src="@/assets/images/icons/copy_link.svg" class="w-auto" alt="Copy Link"/>
                </button>
            </li>
        </ul>
    </div>
</template>
<script>
import ShareIcon from '@/components/Svg/ShareIcon.vue';

export default {
    name: 'share',
    props: {
        shareTitle: {
            type: String,
            default: ''
        }
    },
    components: {
        ShareIcon
    },
    methods: {
        share(refName) {
            if(this.$refs[refName] === null) return;

            clearInterval(this.$refs[refName].popupInterval);
            this.$refs[refName].popupWindow = undefined;
        },
        ladderHandler() {
            return window.location.href;
        },
        copy() {
            this.$copyText(window.location.href);
        }
    }
}
</script>
<style lang="scss" scoped>
.share-wrapper{
    .title{
        margin-right: 20px;
        .icon{
            margin-right: 5px;
            color:        var(--secondary-color);
        }
    }
    .share-list{
        gap: 5px;
        .share-btn{
            padding:         0;
            width:           40px;
            height:          40px;
            border:          1px solid var(--gray-dark-color);
            border-radius:   5px;
            display:         flex;
            align-items:     center;
            justify-content: center;
        }
    }
}
</style>