export const defaultMixins = {
    methods: {
        comingSoon() {
            alert('敬請期待');
        },
        async validate(isInModal) {
            const isPass = await this.$validator.validateAll();
            const items = this.$validator.errors.items;

            this.$nextTick(async() => {
                if(items.length === 0) return;

                const modal = document.querySelector('.modal-open');
                const body = document.body;

                const container = isInModal ? modal : body;
                const offsetTop = isInModal ? body.clientHeight * 0.25 : 250;
                const scrollEl = document.querySelector(`[name="${items[0].field}"]`);

                await container.scrollTo({
                    top: scrollEl.offsetTop - offsetTop,
                    behavior: 'smooth'
                });

                scrollEl.focus();
            });

            return isPass;
        }
    }
}